import { SelectChangeEvent, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { ELoadStatus, ILoadItemStatusRequestDto, ILoadStatusRequestDto, ILoadStatusResponseDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import BaseInfoDialog from "../../../Base/BaseDialogComponent/BaseInfoDialog";
import StatusSelect from "../../../Base/StatusSelectComponent/StatusSelect";
import LoadItemsStatusDialog from "./LoadItemsStatusDialog";
import LoadStatusLegend from "./LoadStatusLegend";

const STATUS_INDEX: Map<ELoadStatus, number> = new Map<ELoadStatus, number>([
    [ELoadStatus.NONE, 0],
    [ELoadStatus.INCOMPLETE, 1],
    [ELoadStatus.PENDING, 2],
    [ELoadStatus.IN_PROGRESS, 3],
    [ELoadStatus.DELIVERED, 4],
    [ELoadStatus.INVOICED, 5],
    [ELoadStatus.UNPAID, 6],
    [ELoadStatus.PAID, 7]
]);

const statusData: string[] = Object.keys(ELoadStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ELoadStatus[ELoadStatus.NONE])
    .map(key => key.toString());

interface IProps {
    open: boolean;
    entityId: string;
    entity: ILoadStatusResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const LoadStatusDialog = (props: IProps) => {
    const { open, entityId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useLoad();
    const formId: string = 'load-status-form';

    const [loading, setLoading] = useState<boolean>(false);
    const [itemStatusDialog, setItemStatusDialog] = useState<boolean>(false);
    const [deliveredStatusDialog, setDeliveredStatusDialog] = useState<boolean>(false);
    const [invoicedStatusDialog, setInvoicedStatusDialog] = useState<boolean>(false);

    const { register, setValue, getValues, handleSubmit, formState: { isValid, isDirty } } = useForm<ILoadStatusRequestDto>({
        defaultValues: {
            status: entity.status,
            itemsStatus: undefined
        }
    });

    const onSubmit = useCallback((data: any) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateStatus(entityId, data);
            if (response) {
                displayNotification({ message: t('Load status was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, t]);

    const itemStatusDialogToggle = useCallback(() => {
        setItemStatusDialog(itemStatusDialog => !itemStatusDialog);
    }, []);

    const deliveredStatusDialogToggle = useCallback(() => {
        setDeliveredStatusDialog(deliveredStatusDialog => !deliveredStatusDialog);
    }, []);

    const invoicedStatusDialogToggle = useCallback(() => {
        setInvoicedStatusDialog(invoicedStatusDialog => !invoicedStatusDialog);
    }, []);

    const validateStatusType = useCallback((value: ELoadStatus) => {
        return ELoadStatus[value] !== undefined && value !== ELoadStatus[ELoadStatus.NONE];
    }, []);

    register('status', { validate: validateStatusType });
    const onChangeStatusHandler = useCallback((event: SelectChangeEvent) => {
        const status: ELoadStatus = event.target.value as ELoadStatus;
        setValue('status', status, {
            shouldValidate: true,
            shouldDirty: true
        });

        const oldIndex: number = STATUS_INDEX.get(entity.status) || 0;
        const newIndex: number = STATUS_INDEX.get(status) || 0;
        const deliveredStatusIndex: number = 4;
        if ((oldIndex >= deliveredStatusIndex && newIndex < deliveredStatusIndex && newIndex > 0)
            || (oldIndex < deliveredStatusIndex && newIndex === 4)) {
            itemStatusDialogToggle();
        }

        if (entity.status === ELoadStatus.INVOICED && status === ELoadStatus.DELIVERED) {
            deliveredStatusDialogToggle();
        }

        if (entity.status === ELoadStatus.DELIVERED && status === ELoadStatus.INVOICED) {
            invoicedStatusDialogToggle();
        }
    }, [
        deliveredStatusDialogToggle, entity.status,
        itemStatusDialogToggle, invoicedStatusDialogToggle, setValue
    ]);

    const disableStatuses = useMemo((): string[] => {
        const defaultStatuses: string[] = [
            ELoadStatus.PENDING.toString(),
            ELoadStatus.IN_PROGRESS.toString(),
            ELoadStatus.DELIVERED.toString(),
            ELoadStatus.INVOICED.toString(),
            ELoadStatus.UNPAID.toString(),
            ELoadStatus.PAID.toString()
        ];

        if (entity.status === ELoadStatus.INCOMPLETE) {
            return defaultStatuses;
        }

        if (entity.status === ELoadStatus.PENDING) {
            return [
                ELoadStatus.INCOMPLETE.toString(),
                ELoadStatus.DELIVERED.toString(),
                ELoadStatus.INVOICED.toString(),
                ELoadStatus.UNPAID.toString(),
                ELoadStatus.PAID.toString()
            ]
        }

        if (entity.status === ELoadStatus.IN_PROGRESS) {
            const statuses: string[] = [
                ELoadStatus.INCOMPLETE.toString(),
                ELoadStatus.INVOICED.toString(),
                ELoadStatus.UNPAID.toString(),
                ELoadStatus.PAID.toString()
            ];

            if (!entity.billOfLading || !entity.rateConfirmation) {
                statuses.push(ELoadStatus.DELIVERED.toString());
            }

            return statuses;
        }

        if (entity.status === ELoadStatus.DELIVERED) {
            return [
                ELoadStatus.INCOMPLETE.toString(),
                ELoadStatus.UNPAID.toString(),
                ELoadStatus.PAID.toString()
            ]
        }

        if (entity.status === ELoadStatus.INVOICED) {
            return [
                ELoadStatus.INCOMPLETE.toString(),
                ELoadStatus.PENDING.toString(),
                ELoadStatus.IN_PROGRESS.toString(),
                ELoadStatus.PAID.toString()
            ]
        }

        if (entity.status === ELoadStatus.UNPAID) {
            return [
                ELoadStatus.INCOMPLETE.toString(),
                ELoadStatus.PENDING.toString(),
                ELoadStatus.IN_PROGRESS.toString(),
                ELoadStatus.DELIVERED.toString()
            ]
        }

        if (entity.status === ELoadStatus.PAID) {
            return [
                ELoadStatus.INCOMPLETE.toString(),
                ELoadStatus.PENDING.toString(),
                ELoadStatus.IN_PROGRESS.toString(),
                ELoadStatus.DELIVERED.toString(),
                ELoadStatus.INVOICED.toString()
            ]
        }

        return [...defaultStatuses, ELoadStatus.INCOMPLETE.toString()];
    }, [entity.billOfLading, entity.rateConfirmation, entity.status]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <StatusSelect
                        required
                        label={t('STATUS')}
                        data={statusData}
                        value={getValues('status')}
                        size='medium'
                        disableItems={disableStatuses}
                        onChange={onChangeStatusHandler}
                    />

                    <LoadStatusLegend />
                </Stack>
            </form>
        );
    }, [
        disableStatuses, getValues, handleSubmit,
        onChangeStatusHandler, onSubmit, t
    ]);

    register('itemsStatus');
    const onLoadItemsStatusSubmitHandler = useCallback((items: ILoadItemStatusRequestDto[]) => {
        setValue('itemsStatus', items, {
            shouldValidate: true,
            shouldDirty: true
        });

        itemStatusDialogToggle();
    }, [itemStatusDialogToggle, setValue]);

    return (
        <>
            <BaseCrudDialog
                loading={loading}
                open={open}
                title={t('EDIT STATUS')}
                maxWidth={'xs'}
                formId={formId}
                buildContent={onBuildContent}
                saveBtnDisabled={!isValid || !isDirty}
                saveBtnLabel={t('SAVE')}
                onCloseBtnClick={onCloseBtnClick}
                closeBtnLabel={t('CLOSE')}
            />

            {itemStatusDialog &&
                <LoadItemsStatusDialog
                    entityId={entityId}
                    open={itemStatusDialog}
                    onCloseBtnClick={itemStatusDialogToggle}
                    onSubmitBtnClick={onLoadItemsStatusSubmitHandler}
                />
            }

            {deliveredStatusDialog &&
                <BaseInfoDialog
                    open={deliveredStatusDialog}
                    title='MARK AS DELIVERED'
                    message='This operation will remove the INVOICE, COMPLETE INVOICE and change the status for Load from INVOICED to DELIVERED.'
                    onCloseBtnClick={deliveredStatusDialogToggle}
                />
            }

            {invoicedStatusDialog &&
                <BaseInfoDialog
                    open={invoicedStatusDialog}
                    title='MARK AS INVOICED'
                    message='Load will be marked as INVOICED and can be downloaded from INVOICE page.'
                    onCloseBtnClick={invoicedStatusDialogToggle}
                />
            }
        </>
    );
}
export default LoadStatusDialog;