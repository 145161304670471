import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../helpers/generalHelper";
import { useLoad } from "../../../hooks/useLoad";
import { useNotification } from '../../../hooks/useNotification';
import { IFilter } from '../../../models/CommonModels';
import { ILoadChargeTypeResponseDto } from "../../../models/LoadModels";
import LoadService from '../../../services/LoadService';
import { RootState } from "../../../store/store";
import BaseCrudGrid from '../../Base/BaseCrudGridComponent/BaseCrudGrid';
import LoadChargeTypeDialog from "./LoadChargeTypeDialog";

const LoadChargeTypeGrid = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { chargeTypesGridRefresh } = useLoad();

    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.chargeTypesGrid);

    const [dialogToggle, setDialogToggle] = useState(false);
    const [chargeType, setChargeType] = useState<ILoadChargeTypeResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchChargeTypes(filter);
    }, []);

    const onAddActionBtnClick = useCallback(() => {
        setChargeType(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((chargeType: ILoadChargeTypeResponseDto) => {
        setChargeType(chargeType);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removeChargeType(rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Charge type${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    chargeTypesGridRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chargeTypesGridRefresh, t]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setChargeType(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        chargeTypesGridRefresh();
    }, [chargeTypesGridRefresh]);

    const onNameColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'name',
            headerName: t('NAME'),
            flex: 1,
            renderCell: onNameColumnRender
        }];
    }, [onNameColumnRender, t]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams, row: any) => {
        setChargeType(row);
        setDialogToggle(true);
    }, []);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {dialogToggle &&
                <LoadChargeTypeDialog
                    open={dialogToggle}
                    entity={chargeType}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default LoadChargeTypeGrid;