import { Autocomplete, AutocompleteRenderInputParams, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../helpers/generalHelper";
import { currencyFormat } from "../../helpers/NumberHelper";
import { ILoadAutocompleteResponseDto } from "../../models/LoadModels";
import LoadService from "../../services/LoadService";

interface IProps {
    label?: string;
    required?: boolean;
    value?: string;
    disabled?: boolean;
    size?: 'medium' | 'small';
    onInit?: (value: ILoadAutocompleteResponseDto | null) => void;
    onChange?: (newValue: ILoadAutocompleteResponseDto | null) => void;
}
const LoadAutocomplete = (props: IProps) => {
    const { size = 'medium', disabled, label = 'Loads', required = false, onChange, onInit, value } = props;

    const [data, setData] = useState<ILoadAutocompleteResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<ILoadAutocompleteResponseDto | null>(null);

    useEffect(() => {
        setData([]);
        setSelectedValue(null);
        (async () => {
            const [, response] = await LoadService.fetchAutocomplete();
            if (response) {
                setData(response.data.body);
            }
        })();
    }, []);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: ILoadAutocompleteResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }

            if (onInit) {
                onInit(selectedValue);
            }
        }
    }, [value, data, onInit, selectedValue]);

    const onChangeHandler = useCallback((event: any, newValue: ILoadAutocompleteResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }

    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: ILoadAutocompleteResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Typography variant='body2'>#{item.idno} {item.customer.name}</Typography>
                <Typography variant='caption' sx={{ fontSize: 10 }}>
                    {`[ RATE: ${currencyFormat(item.rate)} ]`}
                    {item.flatRate && `[ FLAT RATE: ${currencyFormat(item.flatRate)} ]`}
                    {`[ STATUS: ${item.status} ]`}
                </Typography>
            </div>
        );
    }, []);

    const onBuildOptionLabel = useCallback((item: ILoadAutocompleteResponseDto) => {
        return `#${item.idno} ${item.customer.name}`;
    }, []);

    const onBuildOptionKey = useCallback((item: ILoadAutocompleteResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            size={size}
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
        />
    );
}
export default LoadAutocomplete;