import GradeIcon from '@mui/icons-material/Grade';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import PaidIcon from '@mui/icons-material/Paid';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getDateAndTimeFormat, getDateFormat, getTimeFormat } from "../../../helpers/dateHelper";
import { isEmpty, normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { currencyFormat } from '../../../helpers/NumberHelper';
import { isBlank } from '../../../helpers/textHelper';
import { IAddressAndType } from '../../../models/AddressModels';
import { IBaseGridActionBtn, IDateAndTime, IFilter } from '../../../models/CommonModels';
import { ICustomerIdNameResponseDto } from '../../../models/CustomerModels';
import { ELoadStatus, ILoadResponseDto } from '../../../models/LoadModels';
import { ITransportShortResponseDto } from '../../../models/TransportModels';
import { IUserShortResponseDto } from '../../../models/UserModels';
import LoadService from '../../../services/LoadService';
import { RootState } from "../../../store/store";
import AvatarComponent from '../../Base/AvatarComponent/AvatarComponent';
import BaseCrudGrid from "../../Base/BaseCrudGridComponent/BaseCrudGrid";
import DetailsBtn from '../../Base/DetailsBtnComponent/DetailsBtn';
import CompanyAddressManagementDialog from '../../CustomerModule/CompanyAddress/CompanyAddressManagementDialog';
import InvoiceDialog from '../../InvoiceModule/InvoiceDialog';
import LoadChargeTypeManagementDialog from '../ChargeType/LoadChargeTypeManagementDialog';
import PalletTypeManagementDialog from '../PalletType/PalletTypeManagementDialog';
import LoadDialog from './LoadDialog';
import LoadFilter from './LoadFilter';

const usersTransportsColumnStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    gap: '5px'
};

const displayUserDetailsTooltip = (user: IUserShortResponseDto): string => {
    return `${user.fullName} | ${user.role?.toString().replaceAll('_', ' ')}`
}

const displayTransportDetailsTooltip = (transport: ITransportShortResponseDto): string => {
    return `${transport.brand} ${transport.model} | ${transport.transportNumber}`;
}

const LoadGrid = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.grid);

    const [createDialogToggle, setCreateDialogToggle] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);
    const [palletTypeToggle, setPalletTypeToggle] = useState<boolean>(false);
    const [chargeTypeToggle, setChargeTypeToggle] = useState<boolean>(false);
    const [companyAddressToggle, setCompanyAddressToggle] = useState<boolean>(false);
    const [invoiceToggle, setInvoiceToggle] = useState<boolean>(false);
    const [invoiceActionBtnDisabled, setInvoiceActionBtnDisabled] = useState<boolean>(true);
    const [invoiceItems, setInvoiceItems] = useState<string[]>([]);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchAllByFilter(filter);
    }, []);

    const onDetailsActionBtnClick = useCallback((uuid: string) => {
        const newUrl = normalizeNavigationUrl(uuid);
        navigate(`/${newUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownloadActionBtnApiHandler = useCallback((filter: IFilter) => {
        return LoadService.downloadReport(filter);
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onActionsColumnRender = useCallback((record: any) => {
        const row = record.row;
        return [
            <DetailsBtn onClick={() => onDetailsActionBtnClick(row.uuid)} />
        ];
    }, [onDetailsActionBtnClick]);

    const buildIconOnCenterColumn = useCallback((icon: any) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                {icon}
            </Box>
        );
    }, []);

    const onFavoriteColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: boolean = params.value;
        return (
            buildIconOnCenterColumn(value
                ? <GradeIcon color='action' fontSize='small' />
                : <GradeOutlinedIcon color='action' fontSize='small' />
            )
        );
    }, [buildIconOnCenterColumn]);

    const onCustomerColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: ICustomerIdNameResponseDto = params.value;
        return (
            <Tooltip title={value.name.toUpperCase()} sx={{ cursor: 'pointer' }}>
                <Link to={`/customer/${value.uuid}`}>
                    {value.name.toUpperCase()}
                </Link>
            </Tooltip>
        );
    }, []);

    const onStatusColumnRender = useCallback((value: string) => {
        return value.toString().replaceAll('_', ' ');
    }, []);

    const onItemsColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        const shipper: number = row.totalShipperItems || 0;
        const consignee: number = row.totalConsigneeItems || 0;

        return (
            <Box sx={{ display: 'flex', gap: '25px', justifyContent: 'center' }}>
                <Tooltip title={`${t('SHIPPER: ')} ${shipper}`}>
                    <span style={{ color: 'rgb(27, 94, 32)' }}>{shipper}</span>
                </Tooltip>

                <Tooltip title={`${t('CONSIGNEE: ')} ${consignee}`}>
                    <span style={{ color: 'rgb(25, 118, 210)' }}>{consignee}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const onWeightColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        const shipper: number = row.shipperWeight || 0;
        const consignee: number = row.consigneeWeight || 0;

        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                <Tooltip title={`${t('SHIPPER: ')} ${shipper} ${mass}`}>
                    <span style={{ color: 'rgb(27, 94, 32)' }}>{shipper} {mass}</span>
                </Tooltip>

                <Tooltip title={`${t('CONSIGNEE: ')} ${consignee} ${mass}`}>
                    <span style={{ color: 'rgb(25, 118, 210)' }}>{consignee} {mass}</span>
                </Tooltip>
            </Box>
        );
    }, [mass, t]);

    const onAmountColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = params.value ? currencyFormat(params.value) : '';
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onShipDelDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: IDateAndTime[] | undefined = params.value;
        let dates: string = '';

        if (value) {
            dates = value.map((item) => {
                const date: string = getDateFormat(item.date, dateFormat);
                const time: string = getTimeFormat(item.time, timeFormat);
                return `${date}${time ? ` ${time}` : ''}`
            }).join(', ');
        }

        return (
            <Tooltip title={dates}>
                <span>{dates}</span>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onAddressesColumnRender = useCallback((params: GridRenderCellParams) => {
        const addresses: IAddressAndType[] | null = params.value;
        if (addresses === null || isEmpty(addresses)) {
            return '';
        }

        const values: string = addresses.map((item) => item.address).join(' | ');

        return (
            <Tooltip title={values}>
                <span>{values}</span>
            </Tooltip>
        );
    }, []);

    const onUsersColumnRender = useCallback((params: GridRenderCellParams) => {
        const users: IUserShortResponseDto[] | null = params.value;
        if (users === null || isEmpty(users)) {
            return '';
        }

        return (
            <Box sx={usersTransportsColumnStyle}>
                {users.map((user, index) =>
                    <Tooltip key={`load-user-${index}`} title={displayUserDetailsTooltip(user)} placement={'top'}>
                        <Box>
                            <AvatarComponent
                                data={user.avatar}
                                width={27}
                                height={27}
                                defaultValue={user.fullName && !isBlank(user.fullName) ? user.fullName : user.email}
                                color={user.color}
                            />
                        </Box>
                    </Tooltip>
                )}
            </Box>
        );
    }, []);

    const onTransportsColumnRender = useCallback((params: GridRenderCellParams) => {
        const transports: ITransportShortResponseDto[] | null = params.value;
        if (transports === null || isEmpty(transports)) {
            return '';
        }

        return (
            <Box sx={usersTransportsColumnStyle}>
                {transports.map((transport, index) =>
                    <Tooltip key={`load-transport-${index}`} title={displayTransportDetailsTooltip(transport)} placement={'top'}>
                        <Box>
                            <AvatarComponent
                                data={transport.mainImage}
                                width={27}
                                height={27}
                                fullStringValue
                                defaultValue={transport.transportNumber || transport.brand}
                            />
                        </Box>
                    </Tooltip>
                )}
            </Box>
        );
    }, []);

    const onIssueColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadResponseDto = params.row;
        const done: number = row.numberOfDoneIssues || 0;
        const inProgress: number = row.numberOfInProgressIssues || 0;
        const open: number = row.numberOfOpenIssues || 0;
        const unresolved: number = row.numberOfUnresolvedIssues || 0;

        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                <Tooltip title={`${t('OPEN: ')} ${open}`}>
                    <span style={{ color: '#ed6c02' }}>{open}</span>
                </Tooltip>

                <Tooltip title={`${t('IN PROGRESS: ')} ${inProgress}`}>
                    <span style={{ color: 'rgb(27, 94, 32)' }}>{inProgress}</span>
                </Tooltip>

                <Tooltip title={`${t('DONE: ')} ${done}`}>
                    <span style={{ color: 'rgb(25, 118, 210)' }}>{done}</span>
                </Tooltip>

                <Tooltip title={`${t('UNRESOLVED: ')} ${unresolved}`}>
                    <span style={{ color: 'rgb(198, 40, 40)' }}>{unresolved}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            getActions: onActionsColumnRender
        }, {
            field: 'favorite',
            headerName: '',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: onFavoriteColumnRender
        }, {
            field: 'idno',
            headerName: t('ID#'),
            width: 110,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'customer',
            headerName: t('CUSTOMER'),
            width: 275,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onCustomerColumnRender
        }, {
            field: 'shipDates',
            headerName: t('SHIP DATE'),
            width: 170,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onShipDelDateColumnRender
        }, {
            field: 'delDates',
            headerName: t('DEL DATE'),
            width: 170,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onShipDelDateColumnRender
        }, {
            field: 'pickupAddresses',
            headerName: t('PICKUP ADDRESS'),
            flex: 1,
            minWidth: 200,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onAddressesColumnRender
        }, {
            field: 'deliveryAddresses',
            headerName: t('DELIVERY ADDRESS'),
            flex: 1,
            minWidth: 200,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onAddressesColumnRender
        }, {
            field: 'users',
            headerName: t('USERS'),
            width: 180,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onUsersColumnRender
        }, {
            field: 'transports',
            headerName: t('TRANSPORTS'),
            width: 180,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onTransportsColumnRender
        }, {
            field: 'items',
            headerName: t('ITEMS'),
            width: 120,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onItemsColumnRender
        }, {
            field: 'weight',
            headerName: t('WEIGHT'),
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onWeightColumnRender
        }, {
            field: 'rate',
            headerName: t('RATE'),
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'flatRate',
            headerName: t('FLAT RATE'),
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'charges',
            headerName: t('O. CHARGES'),
            width: 115,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'totalRate',
            headerName: t('TOTAL RATE'),
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'issue',
            headerName: t('ISSUE'),
            width: 180,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onIssueColumnRender
        }, {
            field: 'status',
            headerName: t('STATUS'),
            width: 115,
            headerAlign: 'center',
            sortable: false,
            resizable: false,
            valueFormatter: onStatusColumnRender
        }, {
            field: 'created',
            headerName: t('CREATED'),
            width: 180,
            headerAlign: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: onCreatedColumnRender
        }];
    }, [
        onActionsColumnRender, onAmountColumnRender, onCreatedColumnRender,
        onCustomerColumnRender, onFavoriteColumnRender, onItemsColumnRender,
        onStatusColumnRender, onTooltipColumnRender, onWeightColumnRender, t,
        onShipDelDateColumnRender, onAddressesColumnRender, onIssueColumnRender,
        onUsersColumnRender, onTransportsColumnRender
    ]);

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialogToggle(createDialogToggle => !createDialogToggle);
    }, []);

    const onFilterToggleHandler = useCallback(() => {
        setFilterToggle(!filterToggle);
    }, [filterToggle]);

    const onPalletTypeToggleHandler = useCallback(() => {
        setPalletTypeToggle(palletTypeToggle => !palletTypeToggle);
    }, []);

    const onChargeTypeToggleHandler = useCallback(() => {
        setChargeTypeToggle(chargeTypeToggle => !chargeTypeToggle);
    }, []);

    const onCompanyAddressToggleHandler = useCallback(() => {
        setCompanyAddressToggle(companyAddressToggle => !companyAddressToggle);
    }, []);

    const onInvoiceToggleHandler = useCallback(() => {
        setInvoiceToggle(invoiceToggle => !invoiceToggle);
    }, []);

    const onSubmitInvoiceHandler = useCallback(() => {
        setInvoiceItems([]);
    }, []);

    const onSelectionModelChange = useCallback((selectedRows: ILoadResponseDto[]) => {
        const isSelectedItems: boolean = selectedRows.length > 0;
        const allItemsDelivered: boolean = selectedRows.every(item => item.status === ELoadStatus.DELIVERED);

        if (isSelectedItems && allItemsDelivered) {
            const invoiceItems: string[] = selectedRows.map(item => item.uuid);
            setInvoiceItems(invoiceItems);
        }

        setInvoiceActionBtnDisabled(!isSelectedItems || !allItemsDelivered);
    }, []);

    const actionBtns = useMemo((): IBaseGridActionBtn[] => {
        return [{
            tooltip: t('Pallet management'),
            onClick: onPalletTypeToggleHandler,
            icon: <ViewTimelineOutlinedIcon />,
            key: 'pallet-action-btn',
            iconBtn: true
        }, {
            tooltip: t('Address book management'),
            onClick: onCompanyAddressToggleHandler,
            icon: <WhereToVoteIcon />,
            key: 'address-book-action-btn',
            iconBtn: true
        }, {
            tooltip: t('Charge type management'),
            onClick: onChargeTypeToggleHandler,
            icon: <PaidIcon />,
            key: 'charge-type-action-btn',
            iconBtn: true
        }];
    }, [onChargeTypeToggleHandler, onCompanyAddressToggleHandler, onPalletTypeToggleHandler, t]);

    const secondActionBtns = useMemo((): IBaseGridActionBtn[] => {
        return [{
            tooltip: t('Mark selected load(s) as invoiced'),
            onClick: onInvoiceToggleHandler,
            icon: <RequestPageIcon fontSize='small' />,
            label: t('BULK INVOICE'),
            disabled: invoiceActionBtnDisabled,
            key: 'invoice-action-btn'
        }];
    }, [invoiceActionBtnDisabled, onInvoiceToggleHandler, t]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams) => {
        onDetailsActionBtnClick(params.id.toString());
    }, [onDetailsActionBtnClick]);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnTooltip={t('Create load')}
                onAddActionBtnClick={createDialogToggleHandler}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
                filterActionBtnShow
                filterActionBtnTooltip={t('Search load(s)')}
                onFilterActionBtnClick={onFilterToggleHandler}
                downloadActionBtnShow
                downloadActionBtnTooltip={t('Download load(s)')}
                downloadActionBtnApi={onDownloadActionBtnApiHandler}
                actionBtns={actionBtns}
                secondActionBtns={secondActionBtns}
                onRowDoubleClick={onRowDoubleClickHandler}
                onSelectionModelChange={onSelectionModelChange}
            />

            {filterToggle &&
                <LoadFilter
                    open={filterToggle}
                    onClose={onFilterToggleHandler}
                />
            }

            {createDialogToggle &&
                <LoadDialog
                    open={createDialogToggle}
                    onCloseBtnClick={createDialogToggleHandler}
                />
            }

            {palletTypeToggle &&
                <PalletTypeManagementDialog
                    open={palletTypeToggle}
                    onCloseBtnClick={onPalletTypeToggleHandler}
                />
            }

            {companyAddressToggle &&
                <CompanyAddressManagementDialog
                    open={companyAddressToggle}
                    onCloseBtnClick={onCompanyAddressToggleHandler}
                />
            }

            {chargeTypeToggle &&
                <LoadChargeTypeManagementDialog
                    open={chargeTypeToggle}
                    onCloseBtnClick={onChargeTypeToggleHandler}
                />
            }

            {invoiceToggle &&
                <InvoiceDialog
                    open={invoiceToggle}
                    entityIds={invoiceItems}
                    onCloseBtnClick={onInvoiceToggleHandler}
                    onSubmitBtnClick={onSubmitInvoiceHandler}
                />
            }
        </>
    );
}
export default LoadGrid;