import { IFilter } from "../models/CommonModels";
import { EInvoiceStatus, EInvoiceType } from "../models/InvoiceModels";
import { API } from "./APIService";

class InvoiceService {
    public static fetchAllByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'invoices/all', JSON.stringify(filter));
        return [error, response];
    };

    public static fetchByLoadId = async (loadId: string): Promise<any> => {
        const [error, response] = await API('GET', `invoices/load/${loadId}`);
        return [error, response];
    };

    public static create = async (loadIds: string[]): Promise<any> => {
        const [error, response] = await API('POST', 'invoices', loadIds);
        return [error, response];
    };

    public static remove = async (loadIds: string[]): Promise<any> => {
        const [error, response] = await API('DELETE', 'invoices', loadIds);
        return [error, response];
    };

    public static fetchSummaryByFilter = async (filter: IFilter): Promise<any> => {
        const [error, response] = await API('POST', 'invoices/summary', JSON.stringify(filter));
        return [error, response];
    };

    public static downloadInvoices = async (filter: IFilter, type: EInvoiceType): Promise<any> => {
        const [error, response] = await API('POST', `invoices/download/${type}`, JSON.stringify(filter), undefined, undefined, 'blob');
        return [error, response];
    };

    public static updateStatus = async (filter: IFilter, status: EInvoiceStatus): Promise<any> => {
        const [error, response] = await API('PUT', `invoices/status/${status}`, JSON.stringify(filter));
        return [error, response];
    };
}
export default InvoiceService;