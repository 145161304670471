import { TimeView } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useState } from "react";

const views: TimeView[] = ['hours', 'minutes', 'seconds'];
const fullTimeFormat: string = 'hh:mm:ss';
const shortTimeFormat: string = 'hh:mm';
const leadingZero = (value: number) => `0${value}`.slice(-2);

const adjustTime = (value: any): string | undefined => {
    const valueJs: Dayjs = dayjs(value, fullTimeFormat);
    const hours: number = valueJs.hour();
    const minutes: number = valueJs.minute();
    const seconds: number = valueJs.second();

    const result: string = `${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(seconds)}`;
    return isNaN(hours) && isNaN(minutes) ? undefined : result;
}

const getTimeFormat = (value: string): string => {
    const values: string[] = value.match(/:/g) || [];
    return values.length === 2 ? fullTimeFormat : shortTimeFormat;
}

interface IProps {
    label?: string;
    value?: string;
    size?: 'small' | 'medium';
    required?: boolean;
    hideHours?: boolean;
    hideMinutes?: boolean;
    hideSeconds?: boolean;
    fullWidth?: boolean;
    onChange?: (time?: string) => void;
}
const TimeField = (props: IProps) => {
    const {
        label = 'TIME', size = 'small', required, hideHours = false, hideMinutes = false,
        hideSeconds = false, fullWidth = true, value, onChange
    } = props;
    const [selectedValue, setSelectedValue] = useState<any>(value ? dayjs(value, getTimeFormat(value)) : null);

    const onChangeHandler = useCallback((newValue: any) => {
        if (onChange) {
            onChange(adjustTime(newValue));
        }
        setSelectedValue(newValue);
    }, [onChange]);

    if (hideHours) {
        delete views[views.indexOf('hours')];
    }

    if (hideMinutes) {
        delete views[views.indexOf('minutes')];
    }

    if (hideSeconds) {
        delete views[views.indexOf('seconds')];
    }

    return (
        <TimePicker
            label={label}
            views={views}
            value={selectedValue}
            timeSteps={{ minutes: 1, seconds: 1 }}
            slotProps={{
                textField: {
                    required: required,
                    fullWidth: fullWidth,
                    size: size
                },
                field: { clearable: true }
            }}
            onChange={onChangeHandler}
        />
    );
}
export default TimeField;