import { Avatar, Box } from "@mui/material";
import { isEmpty } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import NoAvatarComponent from "./NoAvatarComponent";

const borderStyle = {
	border: '1px dashed silver',
	padding: '5px'
};

const stringToColor = (value: string, color?: string): string => {
	if (color && !isBlank(color)) {
		return color;
	}

	let hash = 0;
	let i;
	for (i = 0; i < value.length; i += 1) {
		hash = value.charCodeAt(i) + ((hash << 5) - hash);
	}

	color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.slice(-2);
	}

	return color;
}

const createStringAvatar = (value: string, fullStringValue: boolean): string => {
	if (fullStringValue) {
		return value;
	}

	let values: string[] = value.split(' ');
	if (isEmpty(values)) {
		values = value.split(".");
	}

	if (values.length === 1) {
		return values[0][0].toUpperCase();
	}

	return `${values[0][0]}${values[1][0]}`.toUpperCase();
}

interface IProps {
	data?: string;
	online?: boolean;
	width?: string | number;
	height?: string | number;
	variant?: 'circular' | 'square';
	border?: boolean;
	defaultValue?: string;
	fullStringValue?: boolean;
	color?: string;
}
const AvatarComponent = (props: IProps) => {
	const {
		data, defaultValue, color, fullStringValue = false, online, width = '30px',
		height = '30px', variant = 'circular', border = false
	} = props;

	const styleImage = {
		height: (typeof 'number') ? Number(height) + 3 : height,
		width: (typeof 'number') ? Number(width) + 3 : width,
	};

	const styleStringImage = {
		width: width,
		height: height,
		bgcolor: defaultValue ? stringToColor(defaultValue, color) : undefined,
		fontSize: '1rem',
		padding: '2px'
	};

	return (
		isBlank(data) && isBlank(defaultValue)
			?
			<NoAvatarComponent square={variant === 'square'} width={width} height={height} />
			:
			<Box sx={border ? borderStyle : {}}>
				{isBlank(data) && defaultValue
					? <Avatar variant={variant} sx={styleStringImage}>{createStringAvatar(defaultValue, fullStringValue)}</Avatar>
					: <Avatar variant={variant} sx={styleImage} alt='' src={`data:image/png;base64,${data}`} />
				}
			</Box>
	);
}
export default AvatarComponent;