import { Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../helpers/textHelper";
import { useNotification } from "../../../hooks/useNotification";
import { IAddressShortResponseDto } from "../../../models/AddressModels";
import { ICompanyAddressRequestDto, ICompanyAddressResponseDto } from "../../../models/CustomerModels";
import CustomerService from "../../../services/CustomerService";
import BaseCrudDialog from "../../Base/BaseCrudDialogComponent/BaseCrudDialog";
import Address from "../../Base/GeolocationComponent/Address";

interface IProps {
    companyAddress?: ICompanyAddressResponseDto;

    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: (entityId?: string) => void;
}
const CompanyAddressDialog = (props: IProps) => {
    const { open, companyAddress, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const formId: string = 'company-address-form';
    const [loading, setLoading] = useState(false);

    const { register, setValue, handleSubmit, formState: { isDirty, isValid, errors } } = useForm<ICompanyAddressRequestDto>({
        defaultValues: {
            name: companyAddress ? companyAddress.name : '',
            addressId: companyAddress ? companyAddress.address.uuid : ''
        }
    });

    const updateData = useCallback((uuid: string, data: ICompanyAddressRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await CustomerService.updateCompanyAddress(uuid, data);
            if (response) {
                displayNotification({ message: t('Address was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const createData = useCallback((data: ICompanyAddressRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await CustomerService.createCompanyAddress(data);
            if (response) {
                displayNotification({ message: t('Address was successfully created.') });

                const uuid = response.data.response.entityId;
                if (onSubmitBtnClick) {
                    onSubmitBtnClick(uuid);
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const onSubmit = useCallback((data: ICompanyAddressRequestDto) => {
        if (companyAddress) {
            updateData(companyAddress.uuid, data);
        } else {
            createData(data);
        }
    }, [createData, companyAddress, updateData]);

    const validateForBlankField = useCallback((value: string) => {
        return !isBlank(value);
    }, []);

    register('addressId', { validate: validateForBlankField });
    const onAddressChangeHandler = useCallback((value: IAddressShortResponseDto | null) => {
        setValue('addressId', value?.uuid || '', {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                    <TextField
                        {...register('name', {
                            required: true,
                            validate: validateForBlankField
                        })}
                        required
                        autoFocus
                        autoComplete='off'
                        label={t('NAME')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 50 } }}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                    />

                    <Address
                        required
                        value={companyAddress?.address}
                        onChange={onAddressChangeHandler}
                    />
                </Stack>
            </form>
        );
    }, [
        companyAddress?.address, errors.name, handleSubmit, onAddressChangeHandler,
        onSubmit, register, t, validateForBlankField
    ]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t(`${companyAddress ? 'EDIT' : 'CREATE'} ADDRESS`)}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default CompanyAddressDialog;