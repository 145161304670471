import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EDateFormat, ELanguage, EMeasureConsumption, EMeasureDistance, EMeasureEnginePower, EMeasureEngineVolume, EMeasureMass, EMeasureSize, ETemperature, ETimeFormat, IGlobalPreference, ISystemPreference, IUserPreference } from "../../models/PreferenceModels";

interface PreferenceState {
    global: IGlobalPreference;
    user: IUserPreference;
    system: ISystemPreference;
};

const initialState: PreferenceState = {
    global: {
        size: EMeasureSize.INCH,
        distance: EMeasureDistance.MILE,
        consumption: EMeasureConsumption.MILES_PER_GALLON,
        mass: EMeasureMass.POUND,
        engineVolume: EMeasureEngineVolume.LITER,
        enginePower: EMeasureEnginePower.HORSE_POWER,
        temperature: ETemperature.FAHRENHEIT,
        companyName: '',
        companyLogo: '',
        companyAddress: {
            uuid: '',
            value: ''
        },
        companyPhone: '',
        companyEmail: '',
        companyFax: '',
        invoiceRemitPaymentEnabled: false,
        invoiceRemitPaymentName: '',
        invoiceRemitPaymentAddress: {
            uuid: '',
            value: ''
        },
        dateFormat: EDateFormat.yyyy_mm_dd,
        timeFormat: ETimeFormat.h_12,
        loaded: false
    },
    user: {
        language: ELanguage.EN,
        webApp: '',
        dateFormat: EDateFormat.yyyy_mm_dd,
        timeFormat: ETimeFormat.h_12,
        notificationPushEnabled: false,
        notificationEmailEnabled: false,
        loaded: false
    },
    system: {
        hostWithDomain: '',
        corporateEmailHostDomain: '',
        googleMapsCommonServiceApiKey: '',
        googleMapsIdKey: '',
        loaded: false
    }
};

export const PreferenceSlice = createSlice({
    name: 'preferenceSlice',
    initialState: initialState,
    reducers: {
        updateGlobalPreference(state: PreferenceState, action: PayloadAction<IGlobalPreference>) {
            state.global = action.payload;
        },
        updateUserPreference(state: PreferenceState, action: PayloadAction<IUserPreference>) {
            state.user = action.payload;
        },
        updateSystemPreference(state: PreferenceState, action: PayloadAction<ISystemPreference>) {
            state.system = action.payload;
        }
    }
});

export const PreferenceActions = PreferenceSlice.actions;
export const PreferenceReducer = PreferenceSlice.reducer;