import { IFileDetailsResponseDto } from "./FileModel";
import { IBrandRequestDto, IBrandResponseDto } from "./MerchandiseModels";

export enum ETransportStatus {
    NONE,
    INCOMPLETE = 'INCOMPLETE',
    ACTIVE = 'ACTIVE',
    TEMPORARY = 'TEMPORARY',
    INACTIVE = 'INACTIVE'
}

export enum ETransmissionType {
    NONE,
    MANUAL = 'MANUAL',
    AUTO = 'AUTO'
}

export enum EVehicleType {
    NONE,
    TRUCK = 'TRUCK',
    TRAILER = 'TRAILER',
    BOX_TRUCK = 'BOX_TRUCK'
}

export enum ETransportDocumentType {
    NONE,
    ANNUAL_DOT = 'ANNUAL_DOT',
    LICENSE_PLATE = 'LICENSE_PLATE',
    DOCUMENT = 'DOCUMENT'
}

export enum EFuelType {
    NONE,
    GASOLINE = 'GASOLINE',
    DIESEL = 'DIESEL',
    ELECTRICITY = 'ELECTRICITY',
    HYBRID = 'HYBRID'
}

export interface ITransportResponseDto {
    uuid: string;
    type: EVehicleType;
    brand: string;
    model: string;
    vin: string;
    licensePlate: string;
    transportNumber: string;
    year: number;
    color?: string;
    height: number;
    width: number;
    length: number;
    weight: number;
    grossWeight: number;
    avatar?: string;
    numberOfOpenIssues?: number;
    numberOfInProgressIssues?: number;
    numberOfDoneIssues?: number;
    numberOfUnresolvedIssues?: number;
    activeFromDate?: number;
    activeToDate?: number;
    status: ETransportStatus;
    favorite: boolean;
    createdBy: string;
    createdDate: number;
    updatedBy?: string;
    updatedDate?: number;
}

export interface ITransportCoverFaceResponseDto {
    uuid: string;
    type: EVehicleType;
    brand: string;
    model: string;
    licensePlate: string;
    transportNumber: string;
    year: number;
    avatar?: string;
    activeFromDate?: number;
    activeToDate?: number;
    status: ETransportStatus;
    favorite: boolean;
    readonly: boolean;
}

export interface ITransportRequestDto {
    vehicleId: string;
    vin: string;
    licensePlate: string;
    transportNumber: string;
    color?: string;
}

export interface ITransportOverviewResponseDto {
    uuid: string;
    vehicle: IVehicleIdNameTypeYearResponseDto;
    vin: string;
    licensePlate: string;
    transportNumber: string;
    color?: string;
}

export interface IVehicleResponseDto {
    uuid: string;
    brand: IBrandResponseDto;
    model: string;
    type: EVehicleType;
    year: number;
    height: number;
    width: number;
    length: number;
    weight: number;
    grossWeight: number;
    fuelType?: EFuelType;
    fuelConsumption?: number;
    transmissionType?: ETransmissionType;
    enginePower?: number;
}

export interface IVehicleIdNameTypeYearResponseDto {
    uuid: string;
    name: string;
    type: EVehicleType;
    year: number;
}

export interface IVehicleRequestDto {
    brand: IBrandRequestDto;
    model: string;
    type: EVehicleType;
    year: number;
    height: number;
    width: number;
    length: number;
    weight: number;
    grossWeight: number;
    fuelType?: EFuelType;
    fuelConsumption?: number;
    transmissionType?: ETransmissionType;
    enginePower?: number;
}

export interface IVehicleAutocompleteResponseDto {
    uuid: string;
    name: string;
    year: number;
    fuelType?: EFuelType;
}

export interface ITransportDocumentRequestDto {
    type: ETransportDocumentType;
    expirationDate?: number;
    number?: string;
    caption?: string;
}

export interface ITransportStatusResponseDto {
    uuid: string;
    activeFromDate?: number;
    activeToDate?: number;
    status: ETransportStatus;
}

export interface ITransportImageResponseDto {
    uuid: string;
    originalFile: IFileDetailsResponseDto;
    thumbnail?: IFileDetailsResponseDto;
}

export interface ITransportStatusRequestDto {
    uuid: string;
    activeFromDate?: number;
    activeToDate?: number;
    status: ETransportStatus;
}

export interface ITransportAutocompleteResponseDto {
    uuid: string;
    type: EVehicleType;
    brand: string;
    model: string;
    licensePlate: string;
    transportNumber: string;
    year: number;
    color?: string;
    avatar?: string;
}

export interface IFavoritesAuto {
    brand: string,
    color: string,
    licensePlate: string,
    mainImage: string,
    model: string,
    transportNumber: string,
    uuid: string,
    vin: string
}

export interface ITransportShortResponseDto {
    uuid: string;
    brand: string;
    model: string;
    vin: string;
    transportNumber?: string;
    licensePlate?: string;
    color?: string;
    weight: number;
    grossWeight: number;
    mainImage?: string;
}