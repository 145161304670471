import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISocketConversationMessage } from "../../models/PersonalChatModels";

interface CnfState {
    chat: {
        refresh: boolean,
        refreshRead: boolean,
        readMessageSocket: string,
        lastMessage: ISocketConversationMessage | null,
        partnerId: string,
        conversationId: string,
        autoRead: boolean
    }
}

const initialState: CnfState = {
    chat: {
        refresh: false,
        refreshRead: false,
        lastMessage: null,
        partnerId: '',
        conversationId: '',
        readMessageSocket: '',
        autoRead: false
    }
}

export const cnfSlice = createSlice({
    name: 'cnf',
    initialState,
    reducers: {
        modifyChatLastMessage(state, action: PayloadAction<ISocketConversationMessage | null>) {
            if (state.chat.conversationId === '*') {
                state.chat.lastMessage = action.payload;
                return
            }

            if (action.payload?.conversationId !== state.chat.conversationId) {
                state.chat.lastMessage = null;
            } else {
                state.chat.lastMessage = action.payload;
            }
        },
        modifyChatRefresh(state, action: PayloadAction<string>) {
            if (action.payload === '*') {
                state.chat.refresh = !state.chat.refresh;
                state.chat.partnerId = state.chat.conversationId;
            } else if (state.chat.conversationId !== action.payload || !state.chat.autoRead) {
                state.chat.refresh = !state.chat.refresh;
                state.chat.partnerId = action.payload
            }
        },
        modifyChatConversationId(state, action: PayloadAction<string>) {
            state.chat.conversationId = action.payload;
        },
        modifyChatAutoRead(state, action: PayloadAction<boolean>) {
            state.chat.autoRead = action.payload;
        },
        modifyChatPartnerId(state, action: PayloadAction<string>) {
            state.chat.partnerId = action.payload;
        },
        modifyChatMessageReadRefresh(state, action: PayloadAction<string>) {
            state.chat.refreshRead = !state.chat.refreshRead;
            state.chat.readMessageSocket = action.payload
        }
    }
})

export const {
    modifyChatMessageReadRefresh,
    modifyChatAutoRead,
    modifyChatLastMessage,
    modifyChatConversationId,
    modifyChatPartnerId,
    modifyChatRefresh
} = cnfSlice.actions;
export default cnfSlice.reducer;