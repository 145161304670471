import { useDispatch } from "react-redux";
import { ICriteria } from "../models/CommonModels";
import { LoadActions } from "../store/reducers/loadSlice";

export const useLoad = () => {
    const dispatch = useDispatch();

    const resetState = () => {
        dispatch(LoadActions.resetState());
    };

    const gridRefresh = () => {
        dispatch(LoadActions.gridRefresh());
    };

    const applyFilter = (criteria: ICriteria[]) => {
        dispatch(LoadActions.applyFilter(criteria));
    };

    const stepRefresh = () => {
        dispatch(LoadActions.stepRefresh());
    };

    const gridUnselectRows = () => {
        dispatch(LoadActions.gridUnselectRows());
    };

    const palletTypeGridRefresh = () => {
        dispatch(LoadActions.palletTypeGridRefresh());
    };

    const itemsGridRefresh = () => {
        dispatch(LoadActions.itemsGridRefresh());
    };

    const paysGridRefresh = () => {
        dispatch(LoadActions.paysGridRefresh());
    };

    const usersGridRefresh = () => {
        dispatch(LoadActions.usersGridRefresh());
    };

    const transportsGridRefresh = () => {
        dispatch(LoadActions.transportsGridRefresh());
    };

    const chargeTypesGridRefresh = () => {
        dispatch(LoadActions.chargeTypesGridRefresh());
    };

    const chargesGridRefresh = () => {
        dispatch(LoadActions.chargesGridRefresh());
    };

    return {
        resetState, gridRefresh, applyFilter, stepRefresh, gridUnselectRows, palletTypeGridRefresh,
        itemsGridRefresh, paysGridRefresh, transportsGridRefresh, usersGridRefresh, chargeTypesGridRefresh,
        chargesGridRefresh
    } as const;
};