import GradeIcon from '@mui/icons-material/Grade';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertTimestampToDateStrWithFormatOrEmpty, getDateAndTimeFormat } from "../../../helpers/dateHelper";
import { normalizeNavigationUrl } from "../../../helpers/generalHelper";
import { isBlank } from '../../../helpers/textHelper';
import { IFilter } from '../../../models/CommonModels';
import { EUserStatus, IUserResponseDto } from '../../../models/UserModels';
import UserService from '../../../services/UserService';
import { RootState } from "../../../store/store";
import AvatarComponent from '../../Base/AvatarComponent/AvatarComponent';
import BaseCrudGrid from "../../Base/BaseCrudGridComponent/BaseCrudGrid";
import DetailsBtn from '../../Base/DetailsBtnComponent/DetailsBtn';
import UserDialog from './UserDialog';
import UserFilter from './UserFilter';

const UserGrid = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.userSlice.grid);

    const [createDialogToggle, setCreateDialogToggle] = useState<boolean>(false);
    const [filterToggle, setFilterToggle] = useState<boolean>(false);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return UserService.fetchAllByFilter(filter);
    }, []);

    const onDetailsActionBtnClick = useCallback((uuid: string) => {
        const newUrl = normalizeNavigationUrl(uuid);
        navigate(`/${newUrl}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDownloadActionBtnApiHandler = useCallback((filter: IFilter) => {
        return UserService.downloadReport(filter);
    }, []);

    const onAvatarColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: IUserResponseDto = params.row;
        const defaultValue: string = row.fullName && !isBlank(row.fullName) ? row.fullName : row.email;
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                <AvatarComponent
                    data={params.value}
                    width={27}
                    height={27}
                    color={row.color}
                    defaultValue={defaultValue}
                />
            </Box>
        );
    }, []);

    const onRoleColumnRender = useCallback((value: string) => {
        return value.toString().replaceAll('_', ' ');
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onActionsColumnRender = useCallback((record: any) => {
        const row = record.row;
        return [
            <DetailsBtn onClick={() => onDetailsActionBtnClick(row.uuid)} />
        ];
    }, [onDetailsActionBtnClick]);

    const buildIconOnCenterColumn = useCallback((icon: any) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                {icon}
            </Box>
        );
    }, []);

    const onFavoriteColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: boolean = params.value;
        return (
            buildIconOnCenterColumn(value
                ? <GradeIcon color='action' fontSize='small' />
                : <GradeOutlinedIcon color='action' fontSize='small' />
            )
        );
    }, [buildIconOnCenterColumn]);

    const convertDateToString = useCallback((value?: number) => {
        return convertTimestampToDateStrWithFormatOrEmpty(value, dateFormat);
    }, [dateFormat]);

    const onLastActivityDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = getDateAndTimeFormat(params.value, dateFormat, timeFormat);
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const getActiveStatusDate = useCallback((activeFromDate?: number, activeToDate?: number): string => {
        if (activeFromDate && activeToDate) {
            return `${convertDateToString(activeFromDate)} - ${convertDateToString(activeToDate)}`;
        }
        return activeFromDate ? `${t('FROM ')} ${convertDateToString(activeFromDate)}` : `${t('TO ')} ${convertDateToString(activeToDate)}`;
    }, [convertDateToString, t]);

    const onStatusColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: EUserStatus = params.value;

        let result: string = value.toString().replaceAll('_', ' ');
        if (value === EUserStatus.TEMPORARY) {
            const row: IUserResponseDto = params.row;
            result += ` ${getActiveStatusDate(row.activeFromDate, row.activeToDate)}`;
            return (
                <Tooltip title={result}>
                    <span>{result}</span>
                </Tooltip>
            );
        }

        return result;
    }, [getActiveStatusDate]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            getActions: onActionsColumnRender
        }, {
            field: 'favorite',
            headerName: '',
            width: 50,
            align: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: onFavoriteColumnRender
        }, {
            field: 'avatar',
            headerName: '',
            width: 45,
            headerAlign: 'center',
            sortable: false,
            resizable: false,
            disableColumnMenu: true,
            renderCell: onAvatarColumnRender
        }, {
            field: 'fullName',
            headerName: t('FULL NAME'),
            flex: 1,
            minWidth: 250,
            headerAlign: 'center',
            sortable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'email',
            headerName: t('EMAIL'),
            flex: 1,
            minWidth: 350,
            headerAlign: 'center',
            sortable: false,
            renderCell: onTooltipColumnRender
        }, {
            field: 'role',
            headerName: t('ROLE'),
            width: 200,
            headerAlign: 'center',
            sortable: false,
            valueFormatter: onRoleColumnRender
        }, {
            field: 'lastActivityDate',
            headerName: t('LAST ACTIVITY'),
            width: 160,
            headerAlign: 'center',
            sortable: false,
            renderCell: onLastActivityDateColumnRender
        }, {
            field: 'status',
            headerName: t('STATUS'),
            width: 270,
            headerAlign: 'center',
            sortable: false,
            renderCell: onStatusColumnRender
        }, {
            field: 'created',
            headerName: t('CREATED'),
            width: 180,
            headerAlign: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: onCreatedColumnRender
        }];
    }, [
        onActionsColumnRender, onAvatarColumnRender, onCreatedColumnRender,
        onFavoriteColumnRender, onLastActivityDateColumnRender, onRoleColumnRender,
        onStatusColumnRender, onTooltipColumnRender, t
    ]);

    const createDialogToggleHandler = useCallback(() => {
        setCreateDialogToggle(createDialogToggle => !createDialogToggle);
    }, []);

    const onFilterToggleHandler = useCallback(() => {
        setFilterToggle(!filterToggle);
    }, [filterToggle]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams) => {
        onDetailsActionBtnClick(params.id.toString());
    }, [onDetailsActionBtnClick]);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnTooltip={t('Create user')}
                onAddActionBtnClick={createDialogToggleHandler}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
                filterActionBtnShow
                filterActionBtnTooltip={t('Search user(s)')}
                onFilterActionBtnClick={onFilterToggleHandler}
                downloadActionBtnShow
                downloadActionBtnTooltip={t('Download user(s)')}
                downloadActionBtnApi={onDownloadActionBtnApiHandler}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {filterToggle &&
                <UserFilter
                    open={filterToggle}
                    onClose={onFilterToggleHandler}
                />
            }

            {createDialogToggle &&
                <UserDialog
                    open={createDialogToggle}
                    onCloseBtnClick={createDialogToggleHandler}
                />
            }
        </>
    );
}
export default UserGrid;