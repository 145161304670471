import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Chip } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from 'react-i18next';
import { generateUniqueId, isEmpty } from '../../../helpers/generalHelper';
import { ILoadItemPalletTypeQuantityRequestDto, ILoadItemPalletTypeQuantityResponseDto } from '../../../models/LoadModels';
import NoData from '../../Base/NoDataComponent/NoData';
import PalletTypeQuantityDialog from './PalletTypeQuantityDialog';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const convertResponseToRequest = (data: ILoadItemPalletTypeQuantityResponseDto[]): ILoadItemPalletTypeQuantityRequestDto[] => {
    let result: ILoadItemPalletTypeQuantityRequestDto[] = [];
    data.forEach((item) => {
        result.push({
            palletTypeId: item.pallet.uuid,
            quantity: item.quantity
        });
    });
    return result;
};

interface IProps {
    data?: ILoadItemPalletTypeQuantityResponseDto[];
    onChange: (pallets?: ILoadItemPalletTypeQuantityRequestDto[]) => void;
}
const PalletTypeQuantity = (props: IProps) => {
    const { onChange } = props;
    
    const { t } = useTranslation();
    const { size } = useSelector((state: RootState) => state.preferenceSlice.global);
    const [dialogToggle, setDialogToggle] = useState<boolean>(false);
    const [data, setData] = useState<ILoadItemPalletTypeQuantityResponseDto[]>(props.data || []);

    const onDialogToggleHandler = useCallback(() => {
        setDialogToggle(dialogToggle => !dialogToggle);
    }, []);

    const onDialogSubmitHandler = useCallback((pallet: ILoadItemPalletTypeQuantityRequestDto, response: ILoadItemPalletTypeQuantityResponseDto) => {
        let newPalletsRequest: ILoadItemPalletTypeQuantityRequestDto[] = [];
        if (data) {
            newPalletsRequest = convertResponseToRequest(data);
        }
        newPalletsRequest.push(pallet);

        let newData: ILoadItemPalletTypeQuantityResponseDto[] = [...data];
        newData.push({ ...response, uuid: generateUniqueId() });
        setData(newData);

        onChange(newPalletsRequest);
        onDialogToggleHandler();
    }, [data, onChange, onDialogToggleHandler]);

    const onRemoveItemHandler = useCallback((pallet: ILoadItemPalletTypeQuantityResponseDto) => {
        const newData: ILoadItemPalletTypeQuantityResponseDto[] = data.filter(item => item.uuid !== pallet.uuid);

        onChange(convertResponseToRequest(newData));
        setData(newData);
    }, [data, onChange]);

    return (
        <Box>
            <Button size='small' color='inherit' variant='outlined' onClick={onDialogToggleHandler}>
                <AddIcon fontSize='small' />&nbsp;{t('PALLET')}
            </Button>
            <Box
                marginTop={1}
                border='2px dashed #808080'
                borderRadius={'5px'}
                padding={1}
                height={285}
                sx={{ overflowY: 'scroll', display: 'flex', flexWrap: 'wrap', gap: '5px' }}
            >
                {data.map((item, index) =>
                    <Chip
                        key={index}
                        variant="outlined"
                        label={`${item.pallet.name} [ ${item.pallet.length} ${size} (L) x ${item.pallet.width} ${size} (W) x ${item.pallet.height} ${size} (H) ] x${item.quantity}`}
                        onDelete={() => onRemoveItemHandler(item)}
                    />)
                }

                {isEmpty(data) &&
                    <NoData
                        label={t('They are not pallets')}
                        height={280}
                        fullWidth
                    />
                }
            </Box>

            {dialogToggle &&
                <PalletTypeQuantityDialog
                    open={dialogToggle}
                    onCloseBtnClick={onDialogToggleHandler}
                    onSubmitBtnClick={onDialogSubmitHandler}
                />
            }
        </Box>
    );
}
export default PalletTypeQuantity;