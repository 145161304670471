import { Stack, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBlank } from "../../../../helpers/textHelper";
import { emailRegex } from "../../../../helpers/ValidatorHelper";
import { useCustomer } from "../../../../hooks/useCustomer";
import { useNotification } from "../../../../hooks/useNotification";
import { ICustomerContactRequestDto, ICustomerContactResponseDto } from "../../../../models/CustomerModels";
import CustomerService from "../../../../services/CustomerService";
import BaseCrudDialog from "../../../Base/BaseCrudDialogComponent/BaseCrudDialog";

interface IProps {
    open: boolean;
    customerId: string;
    entity: ICustomerContactResponseDto;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const CustomerContactsDialog = (props: IProps) => {
    const { open, customerId, entity, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh } = useCustomer();
    const formId: string = 'customer-contacts-form';

    const [loading, setLoading] = useState(false);

    const { register, setError, handleSubmit, formState: { isValid, isDirty } } = useForm<ICustomerContactRequestDto>({
        defaultValues: {
            email: entity?.email,
            phone: entity?.phone
        }
    });

    const onSubmit = useCallback((data: ICustomerContactRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await CustomerService.updateContacts(customerId, data);
            if (response) {
                displayNotification({ message: t('Customer contacts was successfully updated.') });

                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, gridRefresh, onCloseBtnClick, onSubmitBtnClick, stepRefresh, t]);

    const validateEmailField = useCallback((value?: string) => {
        if (value && !emailRegex.test(value)) {
            const message: string = t('Invalid email address.');
            setError('email', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const validatePhoneField = useCallback((value?: string) => {
        if (value && isBlank(value)) {
            const message: string = t('Invalid phone.');
            setError('phone', { message: message });
            return false;
        }
        return true;
    }, [setError, t]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                    <TextField
                        {...register('email', { validate: validateEmailField })}
                        autoComplete='off'
                        label={t('EMAIL')}
                        type='email'
                        slotProps={{htmlInput: {minLength: 1, maxLength: 50}}}
                    />

                    <TextField
                        {...register('phone', { validate: validatePhoneField })}
                        autoComplete='off'
                        label={t('PHONE')}
                        slotProps={{ htmlInput: { minLength: 1, maxLength: 20 } }}
                    />
                </Stack>
            </form>
        );
    }, [handleSubmit, onSubmit, register, t, validateEmailField, validatePhoneField]);

    return (
        <BaseCrudDialog
            loading={loading}
            open={open}
            title={t('EDIT CONTACTS')}
            maxWidth={'xs'}
            formId={formId}
            buildContent={onBuildContent}
            saveBtnDisabled={!isValid || !isDirty}
            saveBtnLabel={t('SAVE')}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default CustomerContactsDialog;