import { Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateAndTimeFormat } from "../../../../helpers/dateHelper";
import { isEmpty } from "../../../../helpers/generalHelper";
import { roundBorderWithColorBlue, roundBorderWithColorOrange } from "../../../../helpers/gridHelper";
import { currencyFormat } from "../../../../helpers/NumberHelper";
import { useLoad } from "../../../../hooks/useLoad";
import { useNotification } from "../../../../hooks/useNotification";
import { IFilter } from "../../../../models/CommonModels";
import { ELoadPayStatus, ILoadPayResponseDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import LoadPaysDialog from "./LoadPaysDialog";

interface IProps {
    loadId: string;
}
const LoadPaysGrid = (props: IProps) => {
    const { loadId } = props;

    const { t } = useTranslation();
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.paysGrid);
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { displayNotification } = useNotification();
    const { paysGridRefresh } = useLoad();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [pay, setPay] = useState<ILoadPayResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchPays(loadId, filter);
    }, [loadId]);

    const onAddActionBtnClick = useCallback(() => {
        setPay(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((pay: ILoadPayResponseDto) => {
        setPay(pay);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removePay(loadId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Load pay${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    paysGridRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId]);

    const onTypeColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = params.value.toString().replace('_', '/');
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onStatusColumnRender = useCallback((params: GridRenderCellParams) => {
        const status: ELoadPayStatus = params.value;
        const value: string = status.toString().replace('_', ' ');
        const style: {} = ELoadPayStatus.PAID === status
            ? roundBorderWithColorBlue
            : roundBorderWithColorOrange;

        return (<span style={style}>{value}</span>);
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onAmountColumnRender = useCallback((params: GridRenderCellParams) => {
        const style = { color: params.value < 0 ? 'rgb(198, 40, 40)' : '' };
        return (<span style={style}>{currencyFormat(params.value)}</span>);
    }, []);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.row.createdDate, dateFormat, timeFormat);
        const author: string = params.row.createdBy;
        const tooltip: string = `${author} [ ${dateTime} ]`;

        return (
            <Tooltip title={tooltip} placement={"bottom"}>
                <Typography fontSize={'11px'}>
                    {author} <br /> {dateTime}
                </Typography>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'type',
            headerName: t('TYPE'),
            width: 185,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onTypeColumnRender
        }, {
            field: 'caption',
            headerName: t('NOTES'),
            flex: 1,
            minWidth: 150,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onTooltipColumnRender
        }, {
            field: 'amount',
            headerName: t('AMOUNT'),
            width: 100,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            type: 'number',
            renderCell: onAmountColumnRender
        }, {
            field: 'status',
            headerName: t('STATUS'),
            width: 90,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            align: 'center',
            renderCell: onStatusColumnRender
        }, {
            field: 'created',
            headerName: t('CREATED'),
            width: 100,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onCreatedColumnRender
        }];
    }, [
        onAmountColumnRender, onCreatedColumnRender, onStatusColumnRender,
        onTooltipColumnRender, onTypeColumnRender, t
    ]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setPay(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        paysGridRefresh();
    }, [paysGridRefresh]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams, row: any) => {
        setPay(row);
        setDialogToggle(true);
    }, []);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {dialogToggle &&
                <LoadPaysDialog
                    open={dialogToggle}
                    loadId={loadId}
                    pay={pay}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default LoadPaysGrid;