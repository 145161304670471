import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Paper, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from '../../../../helpers/textHelper';
import { emailRegex } from '../../../../helpers/ValidatorHelper';
import { useNotification } from '../../../../hooks/useNotification';
import { usePreference } from '../../../../hooks/usePreference';
import { IAddressShortResponseDto } from '../../../../models/AddressModels';
import { IGlobalPreference, IGlobalPreferenceCompanyRequestDto } from '../../../../models/PreferenceModels';
import GlobalPreferenceService from '../../../../services/GlobalPreferenceService';
import { RootState } from "../../../../store/store";
import BackdropProgress from '../../../Base/BackdropComponent/BackdropProgress';
import Address from "../../../Base/GeolocationComponent/Address";

const formId: string = 'global-preference-company-form';
const GlobalPreferenceCompany = () => {
    const { t } = useTranslation();

    const { companyLogo, companyName, companyAddress, companyEmail, companyPhone, companyFax } = useSelector((state: RootState) => state.preferenceSlice.global);

    const { register, setValue, reset, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<IGlobalPreferenceCompanyRequestDto>({
        defaultValues: {
            name: companyName,
            addressId: companyAddress.uuid,
            email: companyEmail,
            phone: companyPhone,
            fax: companyFax
        }
    });

    const { displayNotification } = useNotification();
    const { updateGlobalPreference } = usePreference();
    const [loading, setLoading] = useState(false);

    const validateIsBlank = useCallback((value: string) => {
        return !isBlank(value);
    }, []);

    const validateEmail = useCallback((value: string) => {
        return value && emailRegex.test(value);
    }, []);

    register('addressId', { validate: validateIsBlank });
    const onChangeAddressHandler = useCallback((address: IAddressShortResponseDto) => {
        setValue('addressId', address.uuid, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const fetchGlobalPreference = useCallback(() => {
        (async () => {
            const [, response] = await GlobalPreferenceService.fetch();
            if (response) {
                const preferences: IGlobalPreference = response.data.body;
                updateGlobalPreference({ ...preferences, loaded: true });

                reset({
                    name: preferences.companyName,
                    addressId: preferences.companyAddress.uuid,
                    email: preferences.companyEmail,
                    phone: preferences.companyPhone,
                    fax: preferences.companyFax
                });
            }
        })();
    }, [reset, updateGlobalPreference]);

    const onSubmit = useCallback((data: IGlobalPreferenceCompanyRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await GlobalPreferenceService.updateCompany(data);
            if (response) {
                displayNotification({ message: t('Preferences was successfully updated.') });
                fetchGlobalPreference();
                setLoading(false);
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchGlobalPreference, t]);

    return (
        <Paper sx={{ marginTop: '10px' }}>
            <BackdropProgress open={loading || false}>
                <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{ padding: '10px' }}>
                        <Stack spacing={2}>
                            <img
                                src={`data:image/png;base64,${companyLogo}`}
                                alt={'Company logo'}
                                width={100}
                                height={100}
                            />

                            <TextField
                                {...register('name', { validate: validateIsBlank })}
                                required
                                autoComplete='off'
                                label={t('NAME')}
                                fullWidth
                                slotProps={{ htmlInput: { minLength: 3, maxLength: 50 } }}
                            />

                            <Address
                                value={companyAddress}
                                required
                                displayInOneRow
                                onChange={onChangeAddressHandler}
                            />

                            <Stack direction='row' spacing={2}>
                                <TextField
                                    {...register('email', { validate: validateEmail })}
                                    fullWidth
                                    required
                                    autoComplete='off'
                                    label={t('EMAIL')}
                                    type='email'
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 58 } }}
                                />

                                <TextField
                                    {...register('phone', { validate: validateIsBlank })}
                                    fullWidth
                                    required
                                    autoComplete='off'
                                    label={t('PHONE')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 20 } }}
                                />

                                <TextField
                                    {...register('fax', { validate: validateIsBlank })}
                                    fullWidth
                                    required
                                    autoComplete='off'
                                    label={t('FAX')}
                                    slotProps={{ htmlInput: { minLength: 1, maxLength: 20 } }}
                                />
                            </Stack>
                        </Stack>

                        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title={t('SAVE')} arrow>
                                <span>
                                    <Button
                                        form={formId}
                                        type='submit'
                                        variant='contained'
                                        disabled={!isValid || !isDirty}
                                        size='small'
                                        startIcon={<SaveAsIcon />}
                                    >
                                        {t('SAVE')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    </Box>
                </form>
            </BackdropProgress>
        </Paper>
    );
}
export default GlobalPreferenceCompany;