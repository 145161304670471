import AddIcon from '@mui/icons-material/Add';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Divider, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ELoadItemDirection } from '../../../../models/LoadModels';

interface IProps {
    onBtnClick: (direction: ELoadItemDirection) => void;
    onCopyBtnClick: (direction: ELoadItemDirection) => void;
    copyDisabled?: boolean;
}
const LoadItemsAddButton = (props: IProps) => {
    const { onBtnClick, onCopyBtnClick, copyDisabled } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    const onClickHandler = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onCloseHandler = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onShipperBtnClick = useCallback(() => {
        onBtnClick(ELoadItemDirection.SHIPPER);
    }, [onBtnClick]);

    const onCopyShipperBtnClick = useCallback(() => {
        onCopyBtnClick(ELoadItemDirection.SHIPPER);
    }, [onCopyBtnClick]);

    const onConsigneeBtnClick = useCallback(() => {
        onBtnClick(ELoadItemDirection.CONSIGNEE);
    }, [onBtnClick]);

    const onCopyConsigneeBtnClick = useCallback(() => {
        onCopyBtnClick(ELoadItemDirection.CONSIGNEE);
    }, [onCopyBtnClick]);

    return (
        < Tooltip title={t('Add item')} placement='top' >
            <div>
                <Button
                    aria-label='Load item add menu'
                    onClick={onClickHandler}
                    aria-controls={open ? 'load-item-add-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    variant='outlined'
                    size='small'
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    <AddIcon fontSize='small' />&nbsp;{t('ADD')}
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    id="load-item-add-menu"
                    open={open}
                    onClose={onCloseHandler}
                    onClick={onCloseHandler}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                >
                    <MenuItem onClick={onShipperBtnClick}>
                        <ListItemIcon>
                            <CheckBoxOutlineBlankIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant='caption'>{t('BLANK SHIPPER')}</Typography>
                    </MenuItem>
                    <MenuItem onClick={onCopyShipperBtnClick} disabled={copyDisabled}>
                        <ListItemIcon>
                            <ContentCopyIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant='caption'>{t('COPY SHIPPER')}</Typography>
                    </MenuItem>

                    <Divider />

                    <MenuItem onClick={onConsigneeBtnClick}>
                        <ListItemIcon>
                            <CheckBoxOutlineBlankIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant='caption'>{t('BLANK CONSIGNEE')}</Typography>
                    </MenuItem>
                    <MenuItem onClick={onCopyConsigneeBtnClick} disabled={copyDisabled}>
                        <ListItemIcon>
                            <ContentCopyIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant='caption'>{t('COPY CONSIGNEE')}</Typography>
                    </MenuItem>
                </Menu>
            </div>
        </Tooltip>
    );
}
export default LoadItemsAddButton;