import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { Box, Typography } from "@mui/material";

interface IProps {
    title?: string;
    message: string;
    textAlign?: 'justify' | 'left';
}
const InfoBox = (props: IProps) => {
    const { title, message, textAlign = 'justify' } = props;

    return (
        <Box display={'flex'}>
            <LightbulbOutlinedIcon fontSize='small' color='info' />

            <Typography textAlign={textAlign} variant={'caption'} marginTop={'2px'} marginLeft={1}>
                {
                    title
                        ? <span><b>{title}</b><span> - {message}</span></span>
                        : <b>{message}</b>
                }
            </Typography>
        </Box>
    );
}
export default InfoBox;