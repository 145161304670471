import DoneIcon from '@mui/icons-material/Done';
import { Button, Stack, TextField, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isBlank } from '../../../helpers/textHelper';
import { IAddressRequestDto, IAddressShortResponseDto, ILocationRequestDto } from "../../../models/AddressModels";
import AddressService from '../../../services/AddressService';
import BaseDialog from "../BaseDialogComponent/BaseDialog";
import AddressAutocomplete from "./AddressAutocomplete";
import GoogleMap from './GoogleMap';

const buildStreet = (street: string, poBox: string, apt: string, suite: string): string => {
    if (isBlank(street) && isBlank(poBox)) {
        return '';
    }

    let streetValue: string = '';

    if (!isBlank(street)) {
        streetValue = street;
    }

    if (!isBlank(streetValue) && !isBlank(poBox)) {
        streetValue += ', P.O.BOX ' + poBox;
    } else if (!isBlank(poBox)) {
        streetValue = 'P.O.BOX ' + poBox;
    }

    if (isBlank(streetValue)) {
        return '';
    }

    if (!isBlank(apt)) {
        streetValue += ', APT. ' + apt;
    }

    if (!isBlank(suite)) {
        streetValue += ', SUITE. ' + suite;
    }

    return streetValue;
};

const defaultLocation: ILocationRequestDto = {
    country: '',
    state: '',
    county: '',
    city: '',
    village: '',
    shortCountry: '',
    shortState: '',
    shortCounty: '',
    shortCity: '',
    shortVillage: ''
};

const defaultAddress: IAddressRequestDto = {
    location: defaultLocation,
    street: '',
    zipcode: '',
    latitude: NaN,
    longitude: NaN
};

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
    onApplyBtnClick: (address: IAddressShortResponseDto) => void;
}
const AddressDialog = (props: IProps) => {
    const { open, onCloseBtnClick, onApplyBtnClick } = props;

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [address, setAddress] = useState<IAddressRequestDto>(defaultAddress);
    const [street, setStreet] = useState<string>('');
    const [poBox, setPoBox] = useState<string>('');
    const [apt, setApt] = useState<string>('');
    const [suite, setSuite] = useState<string>('');
    const [isAddress, setIsAddress] = useState<boolean>(false);

    useEffect(() => {
        setIsAddress(!isBlank(address.location.country));
    }, [address.location.country]);

    const onApplyBtnClickHandler = useCallback(() => {
        if (address) {
            (async () => {
                setLoading(true);
                const [error, response] = await AddressService.fetchOrCreate(address);
                if (response) {
                    onApplyBtnClick({ uuid: response.uuid, value: response.fullShortAddress });

                    setLoading(false);
                    onCloseBtnClick();
                }

                if (error) {
                    setLoading(false);
                }
            })();
        }
    }, [address, onApplyBtnClick, onCloseBtnClick]);

    const onChangeAddressHandler = useCallback((address: IAddressRequestDto | null) => {
        const newAddress: IAddressRequestDto = address || defaultAddress;
        setAddress(newAddress);
        setStreet(newAddress.street);
    }, []);

    const onChangeStreetHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const streetValue: string = buildStreet(value, poBox, apt, suite);

        setStreet(value);
        setAddress({ ...address, street: streetValue });
    }, [address, apt, poBox, suite]);

    const onChangePoBoxHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const streetValue: string = buildStreet(street, value, apt, suite);

        setPoBox(value);
        setAddress({ ...address, street: streetValue });
    }, [address, apt, street, suite]);

    const onChangeAptHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const streetValue: string = buildStreet(street, poBox, value, suite);

        setApt(value);
        setAddress({ ...address, street: streetValue });
    }, [address, poBox, street, suite]);

    const onChangeSuiteHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: string = event.target.value;
        const streetValue: string = buildStreet(street, poBox, apt, value);

        setSuite(value);
        setAddress({ ...address, street: streetValue });
    }, [address, apt, poBox, street]);

    const onChangeZipcodeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress({ ...address, zipcode: event.target.value });
    }, [address]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='column' spacing={2}>
                <AddressAutocomplete
                    onChange={onChangeAddressHandler}
                />

                <Stack height={isAddress ? 165 : 300}>
                    <GoogleMap
                        markers={
                            (!isNaN(address.latitude) && !isNaN(address.longitude))
                                ? [{ lat: address.latitude, lng: address.longitude }]
                                : []
                        }
                    />
                </Stack>

                {isAddress &&
                    <Stack direction='column' spacing={2}>
                        <Stack direction='row' spacing={1}>
                            <TextField
                                autoComplete='off'
                                required
                                fullWidth
                                label={t('COUNTRY')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.country}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('STATE')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.state}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('COUNTY')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.county}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('CITY')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.city}
                            />

                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('VILLAGE')}
                                slotProps={{ input: { readOnly: true } }}
                                value={address?.location.village}
                            />
                        </Stack>

                        <Stack direction='row' spacing={1}>
                            <TextField
                                autoComplete='off'
                                fullWidth
                                label={t('STREET')}
                                value={street}
                                error={isBlank(address.street)}
                                onChange={onChangeStreetHandler}
                            />

                            <TextField
                                sx={{ width: 180 }}
                                autoComplete='off'
                                label={t('P.O.BOX')}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                value={poBox}
                                error={isBlank(address.street)}
                                onChange={onChangePoBoxHandler}
                            />

                            <TextField
                                sx={{ width: 180 }}
                                autoComplete='off'
                                label={t('APT.')}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                value={apt}
                                onChange={onChangeAptHandler}
                            />

                            <TextField
                                sx={{ width: 180 }}
                                autoComplete='off'
                                label={t('SUITE')}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                value={suite}
                                onChange={onChangeSuiteHandler}
                            />

                            <TextField
                                sx={{ width: 180 }}
                                autoComplete='off'
                                label={t('ZIPCODE')}
                                value={address?.zipcode}
                                slotProps={{ htmlInput: { minLength: 1, maxLength: 10 } }}
                                onChange={onChangeZipcodeHandler}
                            />
                        </Stack>
                    </Stack>
                }
            </Stack >
        );
    }, [
        address.latitude, address?.location.city, address?.location.country,
        address?.location.county, address?.location.state, address?.location.village,
        address.longitude, address.street, address?.zipcode, onChangeAddressHandler,
        onChangeZipcodeHandler, t, isAddress, apt, onChangeAptHandler,
        onChangePoBoxHandler, onChangeSuiteHandler, poBox, suite,
        onChangeStreetHandler, street
    ]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('APPLY')} arrow>
                <span>
                    <Button
                        variant='contained'
                        disabled={isBlank(address.location.country) || isBlank(address.street)}
                        onClick={onApplyBtnClickHandler}
                        size='small'
                        startIcon={<DoneIcon />}
                    >
                        {t('APPLY')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [address, onApplyBtnClickHandler, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('ADDRESS MANAGEMENT')}
            maxWidth={'md'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default AddressDialog;