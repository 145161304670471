import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDateFormat, getTimeFormat } from "../../../../helpers/dateHelper";
import { isEmpty } from "../../../../helpers/generalHelper";
import { useLoad } from '../../../../hooks/useLoad';
import { useNotification } from "../../../../hooks/useNotification";
import { IFilter } from "../../../../models/CommonModels";
import { ELoadItemAddressType, ELoadItemDirection, ILoadItemDetailsResponseDto, ILoadItemResponseDto } from "../../../../models/LoadModels";
import LoadService from "../../../../services/LoadService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import LoadCopyItemsDialog from "../../CopyItemType/LoadCopyItemsDialog";
import LoadItemsAddButton from './LoadItemsAddButton';
import LoadItemsDialog from "./LoadItemsDialog";

interface IProps {
    loadId: string;
}
const LoadItemsGrid = (props: IProps) => {
    const { loadId } = props;

    const { t } = useTranslation();
    const { mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.loadSlice.itemsGrid);
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh, itemsGridRefresh } = useLoad();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [dialogCopyToggle, setDialogCopyToggle] = useState(false);
    const [entityDirection, setEntityDirection] = useState<ELoadItemDirection>();
    const [entity, setEntity] = useState<ILoadItemDetailsResponseDto>();
    const [copyEntity, setCopyEntity] = useState(false);
    const [count, setCount] = useState<number>(0);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return LoadService.fetchItems(loadId, filter);
    }, [loadId]);

    const onDeleteActionBtnClickHandler = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await LoadService.removeItem(loadId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Load item${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    stepRefresh();
                    itemsGridRefresh();
                    gridRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onMassColumnRender = useCallback((params: GridRenderCellParams) => {
        return `${params.value} ${mass}`
    }, [mass]);

    const onAddressColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadItemResponseDto = params.row;
        let addressTooltip: string | undefined = '';
        let address: string | undefined = '';

        switch (row.addressType) {
            case ELoadItemAddressType.WAREHOUSE:
                addressTooltip = `WAREHOUSE ${row.warehouse?.name} | ${row.warehouse?.address.value}`;
                address = row.warehouse?.address.value;
                break;
            case ELoadItemAddressType.ADDRESS_BOOK:
                addressTooltip = `${row.companyAddress?.name} | ${row.companyAddress?.address.value}`;
                address = row.companyAddress?.address.value;
                break;
        };

        return (
            <Tooltip title={addressTooltip}>
                <span>{address}</span>
            </Tooltip>
        );
    }, []);

    const onDateTimeColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadItemResponseDto = params.row;
        const date: string = getDateFormat(params.value, dateFormat);
        const time: string = getTimeFormat(row.time, timeFormat);
        const value: string = `${date}${time ? ` ${time}` : ''}`
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onPriorityColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: ILoadItemResponseDto = params.row;
        const priority: string = row.priority ? t('YES') : t('NO');
        return (
            <Tooltip title={row.priorityNotes}>
                <span>{priority}</span>
            </Tooltip>
        );
    }, [t]);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'idno',
            headerName: t('ID#'),
            width: 110,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onTooltipColumnRender
        }, {
            field: 'direction',
            headerName: t('DIRECTION'),
            width: 110,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onTooltipColumnRender
        }, {
            field: 'address',
            headerName: t('ADDRESS'),
            minWidth: 110,
            flex: 1,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onAddressColumnRender
        }, {
            field: 'type',
            headerName: t('TYPE'),
            width: 90,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onTooltipColumnRender
        }, {
            field: 'quantity',
            headerName: t('QTY'),
            width: 70,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onTooltipColumnRender
        }, {
            field: 'weight',
            headerName: t('WEIGHT'),
            width: 115,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onMassColumnRender
        }, {
            field: 'date',
            headerName: t('DATE & TIME'),
            width: 165,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onDateTimeColumnRender
        }, {
            field: 'priority',
            headerName: t('PRIORITY'),
            width: 90,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onPriorityColumnRender
        }, {
            field: 'status',
            headerName: t('STATUS'),
            width: 120,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onTooltipColumnRender
        }];
    }, [
        onDateTimeColumnRender, onMassColumnRender, onPriorityColumnRender,
        onTooltipColumnRender, onAddressColumnRender, t
    ]);

    const dialogToggleHandler = useCallback(() => {
        setDialogToggle(dialogToggle => !dialogToggle);
    }, []);

    const displayDialogWithEntity = useCallback((uuid: string) => {
        (async () => {
            const [, response] = await LoadService.fetchItem(loadId, uuid);
            if (response) {
                const data: ILoadItemDetailsResponseDto = response.data.body;
                setEntity(data);
                dialogToggleHandler();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId]);

    const dialogCopyToggleHandler = useCallback(() => {
        setDialogCopyToggle(dialogCopyToggle => !dialogCopyToggle);
    }, []);

    const onSubmitDialogCopyHandler = useCallback((entityId: string) => {
        setCopyEntity(true);
        dialogCopyToggleHandler();
        displayDialogWithEntity(entityId);
    }, [dialogCopyToggleHandler, displayDialogWithEntity]);

    const onCloseDialogHandler = useCallback(() => {
        dialogToggleHandler();
    }, [dialogToggleHandler]);

    const onSubmitDialogHandler = useCallback(() => {
        itemsGridRefresh();
        stepRefresh();
        gridRefresh();
    }, [gridRefresh, itemsGridRefresh, stepRefresh]);

    const onAddBtnClickHandler = useCallback((direction: ELoadItemDirection) => {
        setEntityDirection(direction);
        setEntity(undefined);
        setCopyEntity(false);
        dialogToggleHandler();
    }, [dialogToggleHandler]);

    const onAddCopyBtnClickHandler = useCallback((direction: ELoadItemDirection) => {
        setCopyEntity(false);
        setEntity(undefined);
        setEntityDirection(direction);
        dialogCopyToggleHandler();
    }, [dialogCopyToggleHandler]);

    const onEditActionBtnClickHandler = useCallback((entity: ILoadItemResponseDto) => {
        setCopyEntity(false);
        displayDialogWithEntity(entity.uuid);
    }, [displayDialogWithEntity]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams) => {
        setCopyEntity(false);
        displayDialogWithEntity(params.id.toString());
    }, [displayDialogWithEntity]);

    const onInitDataHandler = useCallback((data: []) => {
        setCount(data.length);
    }, []);

    const buildAddActionBtn = useCallback(() => {
        return (
            <LoadItemsAddButton
                onBtnClick={onAddBtnClickHandler}
                onCopyBtnClick={onAddCopyBtnClickHandler}
                copyDisabled={count === 0}
            />
        );
    }, [count, onAddBtnClickHandler, onAddCopyBtnClickHandler]);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                addCustomActionBtn={buildAddActionBtn()}
                onEditActionBtnClick={onEditActionBtnClickHandler}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClickHandler}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                onRowDoubleClick={onRowDoubleClickHandler}
                refreshActionBtnTooltip={t('Refresh data')}
                onInitData={onInitDataHandler}
            />

            {dialogToggle &&
                <LoadItemsDialog
                    open={dialogToggle}
                    loadId={loadId}
                    direction={entityDirection}
                    entity={entity}
                    copy={copyEntity}
                    onCloseBtnClick={onCloseDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }

            {dialogCopyToggle && entityDirection &&
                <LoadCopyItemsDialog
                    open={dialogCopyToggle}
                    loadId={loadId}
                    direction={entityDirection}
                    onCloseBtnClick={dialogCopyToggleHandler}
                    onSubmitBtnClick={onSubmitDialogCopyHandler}
                />
            }
        </>
    );
}
export default LoadItemsGrid;