import { Autocomplete, AutocompleteRenderInputParams, Box, Divider, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../../helpers/generalHelper";
import { EVehicleType, ITransportShortResponseDto } from "../../../../models/TransportModels";
import LoadService from "../../../../services/LoadService";
import AvatarComponent from '../../../Base/AvatarComponent/AvatarComponent';

interface IProps {
    label?: string;
    required?: boolean;
    transport?: ITransportShortResponseDto;
    value?: string;
    loadId: string;
    type: EVehicleType;
    onChange?: (newValue: ITransportShortResponseDto | null) => void;
}
const LoadTransportsAutocomplete = (props: IProps) => {
    const {
        label = 'Transports', required = false, onChange,
        value, type, transport, loadId
    } = props;

    const [data, setData] = useState<ITransportShortResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<ITransportShortResponseDto | null>(null);

    useEffect(() => {
        if (EVehicleType.NONE !== type && EVehicleType[type] !== undefined) {
            (async () => {
                const [, response] = await LoadService.fetchPendingTransports(loadId, type);
                if (response) {
                    const data: ITransportShortResponseDto[] = response.data.body;
                    if (transport) {
                        data.push(transport);
                    }
                    setData(data);
                }
            })();
        }
    }, [loadId, transport, type]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: ITransportShortResponseDto | null = data.find((item) => item.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }
        }
    }, [value, data]);

    const onChangeHandler = useCallback((event: any, newValue: ITransportShortResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
    }, [onChange]);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const onBuildRenderOption = useCallback((props: object, item: ITransportShortResponseDto) => {
        return (
            <div {...props} key={item.uuid} style={{ display: 'flex', gap: '10px' }}>
                <Box width={'30px'}>
                    <AvatarComponent
                        data={item.mainImage}
                        defaultValue={item.brand}
                    />
                </Box>

                <Box>
                    <Typography variant={'body2'}>{`${item.brand} ${item.model}`.toUpperCase()}</Typography>
                    <Typography variant='caption'>
                        <Divider />
                        {`${item.licensePlate} [ ${item.transportNumber} ]`.toUpperCase()}
                    </Typography>
                </Box>
            </div>
        );
    }, []);

    const onBuildOptionLabel = useCallback((item: ITransportShortResponseDto) => {
        return `${item.brand} ${item.model} - ${item.licensePlate} [ ${item.transportNumber} ]`.toUpperCase();
    }, []);

    const onBuildOptionKey = useCallback((item: ITransportShortResponseDto) => {
        return item.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}
export default LoadTransportsAutocomplete;