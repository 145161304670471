import { ReactNode } from "react";

export enum EActiveInactiveStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE'
}

export enum EPriority {
    NONE = 0,
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3
}

export enum EReputationLevel {
    VIP = 'VIP',
    STANDARD = 'STANDARD',
    POOR = 'POOR',
    NONE = "NONE"
}

export enum EFragilityLevel {
    NONE,
    LOWEST = 'LOWEST',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    HIGHEST = 'HIGHEST'
}

export enum ECriteriaExpression {
    EQUALS = 'equals',
    EQUALS_IGNORE_CASE = 'equalsIgnoreCase',
    NOT_EQUALS = 'notEquals',
    LIKE = 'like',
    LESS_EQUAL_THAN = 'lessEqualThan',
    GREATER_EQUAL_THAN = 'greaterEqualThan',
    GREATER_THAN = 'greaterThan',
    LESS_THAN = 'lessThan',
    BETWEEN_DATE = 'betweenDate',
    IN = 'in',
    NOT_IN = 'notIn',
    IN_OR = 'inOr',
    IS_TRUE = 'isTrue',
    IS_FALSE = 'isFalse'
}

export enum ESortDirection {
    ASC,
    DESC
}

export enum ECurrencyChar {
    USD = '$'
}

export enum ELanguage {
    EN = 'EN'
}

export enum EFrequency {
    NONE,
    MONTHLY = 'MONTHLY',
    WEEKLY = 'WEEKLY'
}

export interface IIdValue {
    uuid: string;
    name: string;
}

export interface IBaseGridActionBtn {
    tooltip?: string;
    onClick: (row: any, selectedRowsIds?: string) => void;
    icon: JSX.Element;
    label?: string;
    disabled?: boolean;
    key: string;
    confirmation?: boolean;
    iconBtn?: boolean;
}

export interface IFilter {
    criteria?: ICriteria[];
    sort?: ISort;
    paging?: IPaging;
}

export interface ICriteria {
    property: string;
    value: any;
    expression: ECriteriaExpression;
}

export interface ISort {
    property: string;
    direction: ESortDirection;
}

export interface IPaging {
    page: number;
    size: number;
}

export interface IBaseActionBtn {
    tooltip?: string;
    onClick: () => void;
    icon: JSX.Element;
    disabled?: boolean;
    key: string;
    confirmation?: boolean;
}

export interface IStepItem {
    title: string;
    content: React.ReactNode;
    disabled?: boolean;
    required?: boolean;
    icon?: React.ReactElement;
}

export interface IItemDetails {
    id: string;
    primary: string;
    secondary?: ReactNode;
    secondaryTextAlign?: 'justify' | 'left',
    hide?: boolean;
}

export interface IDescriptionRequestDto {
    value: string | undefined;
}

export interface IMapMarker {
    lat: number;
    lng: number;
    title?: string;
    content?: string | ReactNode;
}

export interface IMenuAppBarBtn {
    label: string;
    tooltip: string;
    path: string;
    icon: ReactNode;
    disabled?: boolean;
};

export interface IDateAndTime {
    date?: number;
    time?: string;
}