import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isBlank } from "../../../helpers/textHelper";
import { ILoadItemPalletTypeQuantityRequestDto, ILoadItemPalletTypeQuantityResponseDto, IPalletTypeAutocompleteResponseDto } from "../../../models/LoadModels";
import { RootState } from "../../../store/store";
import BaseDialog from '../../Base/BaseDialogComponent/BaseDialog';
import NumberField from "../../Base/NumberFieldComponent/NumberField";
import PalletTypeAutocomplete from "../PalletType/PalletTypeAutocomplete";
import PalletTypeDialog from '../PalletType/PalletTypeDialog';

const palletTypeDefault = {
    uuid: '',
    name: '',
    height: NaN,
    width: NaN,
    length: NaN,
    weight: NaN
};

interface IProps {
    open: boolean;
    onCloseBtnClick: () => void;
    onSubmitBtnClick: (entity: ILoadItemPalletTypeQuantityRequestDto, response: ILoadItemPalletTypeQuantityResponseDto) => void;
}
const PalletTypeQuantityDialog = (props: IProps) => {
    const { open, onCloseBtnClick, onSubmitBtnClick } = props;
    const { t } = useTranslation();
    const { mass, size } = useSelector((state: RootState) => state.preferenceSlice.global);

    const [pallet, setPallet] = useState<ILoadItemPalletTypeQuantityResponseDto>({ uuid: '', pallet: palletTypeDefault, quantity: NaN });
    const [palletToggle, setPalletToggle] = useState<boolean>(false);
    const [palletRefresh, setPalletRefresh] = useState<boolean>(false);

    const { register, setValue, getValues, formState: { isDirty, isValid, errors } } = useForm<ILoadItemPalletTypeQuantityRequestDto>({
        defaultValues: {
            palletTypeId: '',
            quantity: NaN
        }
    });

    const onSubmit = useCallback(() => {
        onSubmitBtnClick(getValues(), pallet);
    }, [getValues, onSubmitBtnClick, pallet]);

    const validatePalletField = useCallback((value: string) => {
        return !isBlank(value);
    }, []);

    register('palletTypeId', { validate: validatePalletField });
    const onPalletChangeHandler = useCallback((value: IPalletTypeAutocompleteResponseDto | null) => {
        setValue('palletTypeId', value ? value.uuid : '', {
            shouldValidate: true,
            shouldDirty: true
        });

        if (value) {
            setPallet({ ...pallet, pallet: { ...value } });
        }
    }, [pallet, setValue]);

    const onPalletInitHandler = useCallback((value: IPalletTypeAutocompleteResponseDto | null) => {
        if (value) {
            setPallet({ ...pallet, pallet: { ...value } });
        }
    }, [pallet]);

    const validateNumberField = useCallback((value?: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('quantity', { validate: validateNumberField });
    const onQuantityChangeHandler = useCallback((value: number) => {
        setValue('quantity', value, {
            shouldValidate: true,
            shouldDirty: true
        });
        setPallet({ ...pallet, quantity: value });
    }, [pallet, setValue]);

    const onPalletToggleHandler = useCallback(() => {
        setPalletToggle(palletToggle => !palletToggle);
    }, []);

    const onSubmitPalletHandler = useCallback((entityId?: string) => {
        if (entityId) {
            setValue('palletTypeId', entityId, {
                shouldValidate: true,
                shouldDirty: true
            });
            setPalletRefresh(palletRefresh => !palletRefresh);
        }
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <Stack spacing={2}>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                    <PalletTypeAutocomplete
                        label={t('PALLET')}
                        required
                        sizeType={size}
                        massType={mass}
                        showDetails
                        refresh={palletRefresh}
                        value={getValues('palletTypeId')}
                        onInit={onPalletInitHandler}
                        onChange={onPalletChangeHandler}
                    />

                    <Tooltip title={t('CREATE PALLET')}>
                        <IconButton onClick={onPalletToggleHandler}>
                            <PlaylistAddIcon />
                        </IconButton>
                    </Tooltip>
                </Box>

                <NumberField
                    required
                    label={t('QUANTITY')}
                    value={getValues('quantity')}
                    scale={0}
                    allowNegative={false}
                    onChange={onQuantityChangeHandler}
                />
            </Stack>
        );
    }, [
        t, size, mass, getValues, onPalletChangeHandler,
        onPalletToggleHandler, onQuantityChangeHandler, palletRefresh,
        onPalletInitHandler
    ]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('SAVE')} arrow>
                <span>
                    <Button
                        variant='contained'
                        disabled={!isValid || !isDirty}
                        size='small'
                        startIcon={<SaveAsIcon />}
                        onClick={onSubmit}
                    >
                        {t('SAVE')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [isDirty, isValid, onSubmit, t]);

    return (
        <>
            <BaseDialog
                open={open}
                title={t('ADD PALLET(S)')}
                maxWidth={'xs'}
                buildContent={onBuildContent}
                buildRightActions={onBuildRightActions}
                onCloseBtnClick={onCloseBtnClick}
                closeBtnLabel={t('CLOSE')}
            />

            {palletToggle &&
                <PalletTypeDialog
                    open={palletToggle}
                    onSubmitBtnClick={onSubmitPalletHandler}
                    onCloseBtnClick={onPalletToggleHandler}
                />
            }
        </>
    );
}
export default PalletTypeQuantityDialog;