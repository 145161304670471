import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Button, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoad } from '../../hooks/useLoad';
import { useNotification } from '../../hooks/useNotification';
import InvoiceService from '../../services/InvoiceService';
import BaseDialog from "../Base/BaseDialogComponent/BaseDialog";

interface IProps {
    open: boolean;
    entityIds: string[];

    message?: string;
    onCloseBtnClick: () => void;
    onSubmitBtnClick?: () => void;
}
const InvoiceDialog = (props: IProps) => {
    const { open, entityIds, message, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { gridRefresh, stepRefresh, gridUnselectRows } = useLoad();
    const [loading, setLoading] = useState(false);

    const onBuildContent = useCallback(() => {
        return (
            <Typography variant='body1'>
                {t(message || 'Selected loads will be marked as INVOICED and can be downloaded from INVOICE page. To confirm, press the SAVE button.')}
            </Typography>
        );
    }, [message, t]);

    const onSubmitBtnClickHandler = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await InvoiceService.create(entityIds);
            if (response) {
                displayNotification({ message: t('Load(s) were marked as invoiced successfully.') });

                gridUnselectRows();
                stepRefresh();
                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                gridRefresh();
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityIds, t]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('MARK LOAD(S) AS INVOICED')} arrow>
                <span>
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<SaveAsIcon />}
                        onClick={onSubmitBtnClickHandler}
                    >
                        {t('SAVE')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [onSubmitBtnClickHandler, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('MARK AS INVOICED')}
            maxWidth={'sm'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default InvoiceDialog;