import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Divider, SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { currencyFormat } from '../../../helpers/NumberHelper';
import { useNotification } from '../../../hooks/useNotification';
import { IFilter } from '../../../models/CommonModels';
import { EInvoiceStatus, IInvoiceSummaryResponseDto } from '../../../models/InvoiceModels';
import InvoiceService from '../../../services/InvoiceService';
import BaseDialog from '../../Base/BaseDialogComponent/BaseDialog';
import StatusSelect from '../../Base/StatusSelectComponent/StatusSelect';

const statusData: string[] = Object.keys(EInvoiceStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== EInvoiceStatus[EInvoiceStatus.NONE])
    .map(key => key.toString());

interface IProps {
    open: boolean;
    filter: IFilter;
    summary: IInvoiceSummaryResponseDto;

    onCloseBtnClick: () => void;
}
const InvoiceStatusDialog = (props: IProps) => {
    const { open, filter, summary, onCloseBtnClick } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<EInvoiceStatus>(EInvoiceStatus.NONE);

    const onSubmitBtnClickHandler = useCallback(() => {
        setLoading(true);
        (async () => {
            const [error, response] = await InvoiceService.updateStatus(filter, status);
            if (response) {
                displayNotification({ message: t('Load(s) status were successfully updated.') });

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
    }, [displayNotification, filter, onCloseBtnClick, status, t]);

    const onChangeStatusHandler = useCallback((event?: SelectChangeEvent) => {
        setStatus(event?.target.value as unknown as EInvoiceStatus);
    }, []);

    const onBuildContent = useCallback(() => {
        return (
            <Stack direction='column' spacing={2}>
                <StatusSelect
                    label={t('STATUS')}
                    data={statusData}
                    value={status}
                    size='medium'
                    onChange={onChangeStatusHandler}
                />

                <Divider />

                <Box>
                    <Box display={'flex'} gap={'5px'}>
                        <Typography variant={'body1'}>
                            <b>{t('TOTAL INVOICES')}:</b>
                        </Typography>

                        <Typography variant={'body1'}>
                            {summary.count}
                        </Typography>
                    </Box>

                    <Box display={'flex'} gap={'5px'}>
                        <Typography variant={'body1'}>
                            <b>{t('TOTAL RATE')}:</b>
                        </Typography>

                        <Typography variant={'body1'}>
                            {currencyFormat(summary.rate)}
                        </Typography>
                    </Box>
                </Box>
            </Stack>
        );
    }, [onChangeStatusHandler, status, summary.count, summary.rate, t]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('Update status')} arrow>
                <span>
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<SaveAsIcon />}
                        disabled={status === EInvoiceStatus.NONE}
                        onClick={onSubmitBtnClickHandler}
                    >
                        {t('SAVE')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [onSubmitBtnClickHandler, status, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('EDIT STATUS')}
            maxWidth={'xs'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default InvoiceStatusDialog;