import { Autocomplete, AutocompleteRenderInputParams, TextField, Typography } from "@mui/material";
import { useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import usePlacesAutocomplete, { getGeocode, Suggestion } from "use-places-autocomplete";
import { isEmpty } from "../../../helpers/generalHelper";
import { isBlank } from "../../../helpers/textHelper";
import { IAddressRequestDto, ILocationRequestDto } from "../../../models/AddressModels";

const defaultLocation: ILocationRequestDto = {
    country: '',
    state: '',
    county: '',
    city: '',
    village: '',
    shortCountry: '',
	shortState: '',
	shortCounty: '',
	shortCity: '',
	shortVillage: ''
};

const defaultAddress: IAddressRequestDto = {
    location: defaultLocation,
    street: '',
    zipcode: '',
    latitude: NaN,
    longitude: NaN
};

const buildResult = (item: any) => {
    const addressParts = item.address_components;
    if (!Array.isArray(addressParts)) {
        return defaultAddress;
    }
    return buildAddressDto(addressParts, item);
};

const buildAddressDto = (addressParts: any[], item: any): IAddressRequestDto => {
    let response: IAddressRequestDto = {
        ...defaultAddress,
        latitude: item.geometry.location.lat(),
        longitude: item.geometry.location.lng()
    };

    addressParts.forEach((component: any) => {
        const types = component.types;
        const value = component.long_name;
        const shortValue = component.short_name;

        if (types.includes('country')) {
            const location: ILocationRequestDto = { ...response.location, country: value, shortCountry: shortValue };
            response = { ...response, location: location };
        }

        if (types.includes('administrative_area_level_2')) {
            const location: ILocationRequestDto = { ...response.location, state: value, shortState: shortValue };
            response = { ...response, location: location };
        }

        if (types.includes('administrative_area_level_1')) {
            const location: ILocationRequestDto = { ...response.location, county: value, shortCounty: shortValue };
            response = { ...response, location: location };
        }

        if (types.includes('locality')) {
            const location: ILocationRequestDto = { ...response.location, city: value, shortCity: shortValue };
            response = { ...response, location: location };
        }

        if (types.includes('vilage')) {
            const location: ILocationRequestDto = { ...response.location, village: value, shortVillage: shortValue };
            response = { ...response, location: location };
        }

        if (types.includes('route')) {
            response = { ...response, street: isBlank(response.street) ? value : `${value} ${response.street}` };
        }

        if (types.includes('street_number')) {
            response = { ...response, street: isBlank(response.street) ? value : `${response.street} ${value}` };
        }

        if (types.includes('postal_code')) {
            response = { ...response, zipcode: value };
        }
    });

    return response;
};

interface IProps {
    onChange: (address: IAddressRequestDto | null) => void;
}
const AddressAutocomplete = (props: IProps) => {
    const { onChange } = props;

    const { t } = useTranslation();
    const { ready, suggestions: { data }, setValue, clearSuggestions } = usePlacesAutocomplete({ debounce: 300 });
    const [selectedValue, setSelectedValue] = useState<Suggestion | null>(null);

    const onChangeHandler = useCallback((event: React.SyntheticEvent, value: Suggestion | null) => {
        setSelectedValue(value);

        if (value) {
            (async () => {
                const result = await getGeocode({ placeId: value?.place_id });
                if (result && !isEmpty(result)) {
                    const address: IAddressRequestDto = buildResult(result[0]);
                    onChange(address);
                    clearSuggestions();
                }
            })();
        } else {
            onChange(null);
        }

    }, [clearSuggestions, onChange]);

    const onFilterOptionsHandler = useCallback((value: any) => {
        return value;
    }, []);

    const onInputChangeHandler = useCallback((event: any, newValue: string) => {
        onChange(null);
        setValue(newValue);
    }, [onChange, setValue]);

    const onBuildOptionLabel = useCallback((item: Suggestion) => {
        return item.description;
    }, []);

    const onBuildOptionKey = useCallback((item: Suggestion) => {
        return `address-item-${item.place_id}`;
    }, []);

    const isOptionEqualToValue = useCallback((option: Suggestion, value: Suggestion) => {
        return option.place_id === value.place_id
    }, []);

    const onBuildRenderOption = useCallback((props: object, item: Suggestion) => {
        return (
            <div {...props} key={`address-item-${item.place_id}`}>
                <Typography variant='body2'>{item.description}</Typography>
            </div>
        );
    }, []);

    const onRenderInputHandler = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={t('ADDRESS')}
                placeholder={t('Enter the desired address...')}
                disabled={!ready}
                autoComplete='off'
            />
        );
    }, [ready, t]);

    return (
        <Autocomplete
            value={selectedValue}
            getOptionLabel={onBuildOptionLabel}
            fullWidth
            size='medium'
            options={data}
            includeInputInList
            filterOptions={onFilterOptionsHandler}
            onChange={onChangeHandler}
            onInputChange={onInputChangeHandler}
            renderInput={onRenderInputHandler}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}
export default AddressAutocomplete;