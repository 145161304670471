import { Autocomplete, AutocompleteRenderInputParams, Box, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../../helpers/generalHelper";
import { ILoadUserPendingResponseDto, ILoadUserResponseDto } from "../../../../models/LoadModels";
import { IAuthority } from '../../../../models/PermissionModels';
import { IUserShortResponseDto } from "../../../../models/UserModels";
import LoadService from "../../../../services/LoadService";
import AvatarComponent from "../../../Base/AvatarComponent/AvatarComponent";
import { isBlank } from "../../../../helpers/textHelper";

interface IProps {
    label?: string;
    required?: boolean;
    loadId: string;
    loadUser?: ILoadUserResponseDto;
    value?: string;
    type: IAuthority;
    onChange?: (newValue: ILoadUserPendingResponseDto | null) => void;
}
const LoadUserAutocomplete = (props: IProps) => {
    const {
        label = 'Users', required = false, onChange,
        loadId, value, type, loadUser
    } = props;

    const [data, setData] = useState<ILoadUserPendingResponseDto[]>([]);
    const [selectedValue, setSelectedValue] = useState<ILoadUserPendingResponseDto | null>(null);

    useEffect(() => {
        if (IAuthority.NONE !== type && IAuthority[type] !== undefined) {
            (async () => {
                const [, response] = await LoadService.fetchPendingUsers(loadId, type);
                if (response) {
                    const data: ILoadUserPendingResponseDto[] = response.data.body;
                    if (loadUser) {
                        data.push({ ...loadUser });
                    }
                    setData(data);
                }
            })();
        }
    }, [loadId, loadUser, type]);

    useEffect(() => {
        if (!isEmpty(data)) {
            if (value) {
                const selectedValue: ILoadUserPendingResponseDto | null = data.find((item) => item.user.uuid === value) || null;
                if (selectedValue) {
                    setSelectedValue(selectedValue);
                }
            } else {
                setSelectedValue(null);
            }
        }
    }, [value, data]);

    const onChangeHandler = useCallback((event: any, newValue: ILoadUserPendingResponseDto | null) => {
        setSelectedValue(newValue);

        if (onChange) {
            onChange(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBuildRenderInput = useCallback((params: AutocompleteRenderInputParams) => {
        return (
            <TextField {...params}
                fullWidth
                label={label}
                required={required}
            />
        );
    }, [label, required]);

    const buildUserData = useCallback((pendingUser: ILoadUserPendingResponseDto) => {
        const user: IUserShortResponseDto = pendingUser.user;
        const involvedActiveLoads: number = pendingUser.involvedActiveLoads;
        return `${user.fullName}${involvedActiveLoads > 0 ? ` [ INVOLVED IN ${involvedActiveLoads} A.L. ]` : ''}`;
    }, []);

    const onBuildRenderOption = useCallback((props: object, item: ILoadUserPendingResponseDto) => {
        const user: IUserShortResponseDto = item.user;
        const defaultValue: string = user.fullName && !isBlank(user.fullName) ? user.fullName : user.email;
        return (
            <div {...props} key={user.uuid} style={{ display: 'flex', gap: '10px' }}>
                <Box width={'30px'}>
                    <AvatarComponent
                        data={user.avatar}
                        color={user.color}
                        defaultValue={defaultValue}
                    />
                </Box>

                <Typography variant={'caption'}>{buildUserData(item)}</Typography>
            </div>
        );
    }, [buildUserData]);

    const onBuildOptionLabel = useCallback((item: ILoadUserPendingResponseDto) => {
        return buildUserData(item);
    }, [buildUserData]);

    const onBuildOptionKey = useCallback((item: ILoadUserPendingResponseDto) => {
        return item.user.uuid;
    }, []);

    const isOptionEqualToValue = useCallback((option: any, value: any) => {
        return option.uuid === value.uuid
    }, []);

    return (
        <Autocomplete
            fullWidth
            options={data}
            value={selectedValue}
            onChange={onChangeHandler}
            getOptionLabel={onBuildOptionLabel}
            getOptionKey={onBuildOptionKey}
            renderOption={onBuildRenderOption}
            renderInput={onBuildRenderInput}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    );
}
export default LoadUserAutocomplete;