import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Box, Checkbox, FormControlLabel, IconButton, SelectChangeEvent, Stack, Tooltip } from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { isBlank } from '../../../../helpers/textHelper';
import { useNotification } from '../../../../hooks/useNotification';
import { EFragilityLevel } from "../../../../models/CommonModels";
import { ICompanyAddressResponseDto } from '../../../../models/CustomerModels';
import { ELoadItemAddressType, ELoadItemDirection, ELoadItemStatus, ELoadItemType, ILoadItemDetailsResponseDto, ILoadItemPalletTypeQuantityRequestDto, ILoadItemPalletTypeQuantityResponseDto, ILoadItemRequestDto } from "../../../../models/LoadModels";
import { IWarehouseAutocompleteResponseDto } from "../../../../models/WarehouseModels";
import LoadService from '../../../../services/LoadService';
import { RootState } from '../../../../store/store';
import BaseCrudDialog from '../../../Base/BaseCrudDialogComponent/BaseCrudDialog';
import DateField from '../../../Base/DateComponent/DateField';
import TimeField from '../../../Base/DateComponent/TimeField';
import IdnoGenerator from '../../../Base/IdnoGeneratorComponent/IdnoGenerator';
import NumberField from "../../../Base/NumberFieldComponent/NumberField";
import StatusSelect from '../../../Base/StatusSelectComponent/StatusSelect';
import TextareaField from '../../../Base/TextareaFieldComponent/TextareaField';
import CompanyAddressAutocomplete from '../../../CustomerModule/CompanyAddress/CompanyAddressAutocomplete';
import CompanyAddressDialog from '../../../CustomerModule/CompanyAddress/CompanyAddressDialog';
import WarehouseDialog from '../../../WarehouseModule/Grid/WarehouseDialog';
import WarehouseAutocomplete from "../../../WarehouseModule/WarehouseAutocomplete";
import LoadItemAddressTypeSelect from "../../AddressType/LoadItemAddressTypeSelect";
import FragilityLevelSelect from "../../Fragility/FragilityLevelSelect";
import PalletTypeQuantity from '../../PalletTypeQuantity/PalletTypeQuantity';
import LoadItemTypeSelect from "../../Type/LoadItemTypeSelect";

const defaultAddress = 'No address has been selected.';

const statusData: string[] = Object.keys(ELoadItemStatus)
    .filter(key => isNaN(Number(key)))
    .filter(key => key !== ELoadItemStatus[ELoadItemStatus.NONE])
    .map(key => key.toString());

const convertPalletsResponseToRequest = (pallets: ILoadItemPalletTypeQuantityResponseDto[])
    : ILoadItemPalletTypeQuantityRequestDto[] => {
    return pallets.map((item: ILoadItemPalletTypeQuantityResponseDto) => {
        return {
            palletTypeId: item.pallet.uuid,
            quantity: item.quantity
        };
    });
}

interface IProps {
    open: boolean;

    loadId: string;
    direction?: ELoadItemDirection;
    entity?: ILoadItemDetailsResponseDto;
    copy?: boolean;

    onCloseBtnClick: () => void;
    onSubmitBtnClick: () => void;
}
const LoadItemsDialog = (props: IProps) => {
    const { open, loadId, direction, entity, copy = false, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const formId: string = 'load-item-form';
    const { displayNotification } = useNotification();
    const { temperature, mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { dateFormat } = useSelector((state: RootState) => state.preferenceSlice.user);

    const [loading, setLoading] = useState(false);
    const [warehouseToggle, setWarehouseToggle] = useState<boolean>(false);
    const [warehouseRefresh, setWarehouseRefresh] = useState<boolean>(false);
    const [companyAddressToggle, setCompanyAddressToggle] = useState<boolean>(false);
    const [companyAddressRefresh, setCompanyAddressRefresh] = useState<boolean>(false);
    const [address, setAddress] = useState<string>(t(defaultAddress));

    const { register, setValue, getValues, setError, clearErrors, handleSubmit, formState: { isValid, isDirty, errors } } = useForm<ILoadItemRequestDto>({
        defaultValues: {
            idno: entity && !copy ? entity.idno : '',
            description: entity ? entity.description || '' : '',
            notes: entity ? entity.notes || '' : '',
            direction: entity && !copy ? entity.direction : direction,
            type: entity ? entity.type : ELoadItemType.NONE,
            fragility: entity ? entity.fragility : EFragilityLevel.NONE,
            quantity: entity ? entity.quantity : NaN,
            weight: entity ? entity.weight : NaN,
            temperature: entity ? entity.temperature : NaN,
            addressType: entity && !copy ? entity.addressType : ELoadItemAddressType.NONE,
            warehouseId: entity && !copy ? entity.warehouse?.uuid : undefined,
            companyAddressId: entity && !copy ? entity.companyAddress?.uuid : undefined,
            priority: entity ? entity.priority : false,
            priorityNotes: entity ? entity.priorityNotes || '' : '',
            date: entity && !copy ? entity.date : NaN,
            time: entity && !copy ? entity.time : undefined,
            pallets: entity && entity.pallets ? convertPalletsResponseToRequest(entity.pallets) : [],
            status: entity && !copy ? entity.status : ELoadItemStatus.OPEN
        }
    });

    const createData = useCallback((data: ILoadItemRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.createItem(loadId, data);
            if (response) {
                displayNotification({ message: t('Load item was successfully created.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const updateData = useCallback((uuid: string, data: ILoadItemRequestDto) => {
        setLoading(true);
        (async () => {
            const [error, response] = await LoadService.updateItem(loadId, uuid, data);
            if (response) {
                displayNotification({ message: t('Load item was successfully updated.') });

                if (onSubmitBtnClick) {
                    onSubmitBtnClick();
                }

                setLoading(false);
                onCloseBtnClick();
            }

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadId, t]);

    const onSubmit = useCallback((data: ILoadItemRequestDto) => {
        if (entity && !copy) {
            updateData(entity.uuid, data);
        } else {
            createData(data);
        }
    }, [copy, createData, entity, updateData]);

    const validateIdnoField = useCallback((value: string) => {
        return !isBlank(value) && !errors?.idno;
    }, [errors?.idno]);

    register('idno', { validate: validateIdnoField });
    const onChangeIdnoHandler = useCallback((value: string, error?: string) => {
        if (error) {
            setError('idno', { message: error });
        } else {
            clearErrors('idno');
        }

        setValue('idno', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [clearErrors, setError, setValue]);

    register('description');
    const onDescriptionChangeHandler = useCallback((value: string | undefined) => {
        setValue('description', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('notes');
    const onNotesChangeHandler = useCallback((value: string | undefined) => {
        setValue('notes', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateLoadItemTypeField = useCallback((value: ELoadItemType) => {
        return ELoadItemType[value] !== undefined && value !== ELoadItemType.NONE;
    }, []);

    register('type', { validate: validateLoadItemTypeField });
    const onLoadItemTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: ELoadItemType = event.target.value as ELoadItemType;
        setValue('type', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateFragilityLevelField = useCallback((value: EFragilityLevel) => {
        return EFragilityLevel[value] !== undefined && value !== EFragilityLevel.NONE;
    }, []);

    register('fragility', { validate: validateFragilityLevelField });
    const onFragilityLevelChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: EFragilityLevel = event.target.value as EFragilityLevel;
        setValue('fragility', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateNumberField = useCallback((value?: number) => {
        return value !== undefined && !isNaN(value);
    }, []);

    register('quantity', { validate: validateNumberField });
    const onQuantityChangeHandler = useCallback((value: number) => {
        setValue('quantity', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('weight', { validate: validateNumberField });
    const onWeightChangeHandler = useCallback((value: number) => {
        setValue('weight', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('temperature');
    const onTemperatureChangeHandler = useCallback((value?: number) => {
        setValue('temperature', value || NaN, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateAddressTypeField = useCallback((value: ELoadItemAddressType) => {
        return ELoadItemAddressType[value] !== undefined && value !== ELoadItemAddressType.NONE;
    }, []);

    register('addressType', { validate: validateAddressTypeField });
    const onAddressTypeChangeHandler = useCallback((event: SelectChangeEvent) => {
        const value: ELoadItemAddressType = event.target.value as ELoadItemAddressType;

        setValue('warehouseId', undefined);
        setValue('companyAddressId', undefined);
        setAddress(defaultAddress);

        setValue('addressType', value || ELoadItemAddressType.NONE, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateAddressField = useCallback(() => {
        return !(
            isBlank(getValues('warehouseId'))
            && isBlank(getValues('companyAddressId'))
        )
    }, [getValues]);

    register('warehouseId', { validate: validateAddressField });
    const onWarehouseChangeHandler = useCallback((value: IWarehouseAutocompleteResponseDto | null) => {
        setValue('warehouseId', value?.uuid || undefined, {
            shouldValidate: true,
            shouldDirty: true
        });
        setAddress(value?.address.value || defaultAddress);
    }, [setValue]);

    const onWarehouseInitHandler = useCallback((value: IWarehouseAutocompleteResponseDto | null) => {
        setAddress(value?.address.value || defaultAddress);
    }, []);

    register('companyAddressId', { validate: validateAddressField });
    const onCompanyAddressChangeHandler = useCallback((value: ICompanyAddressResponseDto | null) => {
        setValue('companyAddressId', value?.uuid || undefined, {
            shouldValidate: true,
            shouldDirty: true
        });
        setAddress(value?.address.value || defaultAddress);
    }, [setValue]);

    const onCompanyAddressInitHandler = useCallback((value: ICompanyAddressResponseDto | null) => {
        setAddress(value?.address.value || defaultAddress);
    }, []);

    register('priority');
    const onPriorityChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;
        setValue('priority', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('priorityNotes');
    const onPriorityNotesChangeHandler = useCallback((value: string) => {
        setValue('priorityNotes', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateDateField = useCallback((value?: number) => {
        if (getValues('time') !== undefined && (value === undefined || isNaN(value))) {
            return false;
        }
        return true;
    }, [getValues]);

    register('date', { validate: validateDateField });
    const onDateChangeHandler = useCallback((value?: number) => {
        setValue('date', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('time');
    const onTimeChangeHandler = useCallback((value?: string) => {
        setValue('time', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    register('pallets');
    const onPalletsChangeHandler = useCallback((value?: ILoadItemPalletTypeQuantityRequestDto[]) => {
        setValue('pallets', value, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const validateStatusField = useCallback((value: ELoadItemStatus) => {
        return ELoadItemStatus[value] !== undefined && value !== ELoadItemStatus.NONE;
    }, []);

    register('status', { validate: validateStatusField });
    const onChangeStatusHandler = useCallback((event: SelectChangeEvent) => {
        setValue('status', event.target.value as ELoadItemStatus, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [setValue]);

    const onWarehouseToggleHandler = useCallback(() => {
        setWarehouseToggle(warehouseToggle => !warehouseToggle);
    }, []);

    const onSubmitWarehouseHandler = useCallback((entityId?: string) => {
        if (entityId) {
            setValue('warehouseId', entityId, {
                shouldValidate: true,
                shouldDirty: true
            });
            setWarehouseRefresh(warehouseRefresh => !warehouseRefresh);
        }
    }, [setValue]);

    const onCompanyAddressToggleHandler = useCallback(() => {
        setCompanyAddressToggle(companyAddressToggle => !companyAddressToggle);
    }, []);

    const onSubmitCompanyAddressHandler = useCallback((entityId?: string) => {
        if (entityId) {
            setValue('companyAddressId', entityId, {
                shouldValidate: true,
                shouldDirty: true
            });
            setCompanyAddressRefresh(companyAddressRefresh => !companyAddressRefresh);
        }
    }, [setValue]);

    const onBuildContent = useCallback(() => {
        return (
            <form id={formId} onSubmit={handleSubmit(onSubmit)}>
                <Stack direction='row' spacing={2}>
                    <Stack direction='column' spacing={2} width={'100%'}>
                        <IdnoGenerator
                            required
                            value={getValues('idno')}
                            label={t('ID#')}
                            generateBtnTooltip={t('GENERATE')}
                            errorMessage={t('The value is used. Choose another value.')}
                            onChange={onChangeIdnoHandler}
                        />

                        <TextareaField
                            label={t('DESCRIPTION')}
                            rows={4.13}
                            value={getValues('description')}
                            onChange={onDescriptionChangeHandler}
                        />

                        <TextareaField
                            label={t(`${direction} NOTES`)}
                            rows={1}
                            maxLength={50}
                            value={getValues('notes')}
                            onChange={onNotesChangeHandler}
                        />

                        <Stack direction='row' spacing={2}>
                            <LoadItemTypeSelect
                                label={t('TYPE')}
                                required
                                value={getValues('type')}
                                onChange={onLoadItemTypeChangeHandler}
                            />

                            <FragilityLevelSelect
                                label={t('FRAGILITY')}
                                required
                                value={getValues('fragility')}
                                onChange={onFragilityLevelChangeHandler}
                            />
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <NumberField
                                required
                                label={t('QUANTITY')}
                                value={getValues('quantity')}
                                scale={0}
                                allowNegative={false}
                                onChange={onQuantityChangeHandler}
                            />

                            <NumberField
                                required
                                label={t('WEIGHT')}
                                value={getValues('weight')}
                                allowNegative={false}
                                units={mass}
                                onChange={onWeightChangeHandler}
                            />
                        </Stack>
                    </Stack>

                    <Stack direction='column' spacing={2} width={'100%'}>
                        <NumberField
                            label={t('TEMPERATURE')}
                            value={getValues('temperature')}
                            allowNegative={false}
                            units={temperature}
                            onChange={onTemperatureChangeHandler}
                        />

                        <LoadItemAddressTypeSelect
                            label={t('ADDRESS TYPE')}
                            required
                            value={getValues('addressType')}
                            onChange={onAddressTypeChangeHandler}
                        />

                        {getValues('addressType') === ELoadItemAddressType.WAREHOUSE &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                                <WarehouseAutocomplete
                                    required
                                    label={t('WAREHOUSE')}
                                    showStatus
                                    showAddress
                                    disableInactiveItems
                                    refresh={warehouseRefresh}
                                    value={getValues('warehouseId')}
                                    onInit={onWarehouseInitHandler}
                                    onChange={onWarehouseChangeHandler}
                                />

                                <Tooltip title={t('CREATE WAREHOUSE')}>
                                    <IconButton onClick={onWarehouseToggleHandler}>
                                        <PlaylistAddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }

                        {getValues('addressType') === ELoadItemAddressType.ADDRESS_BOOK &&
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                                <CompanyAddressAutocomplete
                                    required
                                    label={t('ADDRESS BOOK')}
                                    refresh={companyAddressRefresh}
                                    value={getValues('companyAddressId')}
                                    onInit={onCompanyAddressInitHandler}
                                    onChange={onCompanyAddressChangeHandler}
                                />

                                <Tooltip title={t('CREATE ADDRESS')}>
                                    <IconButton onClick={onCompanyAddressToggleHandler}>
                                        <PlaylistAddIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        }

                        <Box
                            height={getValues('addressType') === ELoadItemAddressType.NONE ? 108 : 36}
                            border='2px dashed #808080'
                            borderRadius={'5px'}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            padding={1}
                            sx={{ fontSize: '0.840rem' }}
                        >
                            {address}
                        </Box>

                        <Stack direction='row' spacing={2}>
                            <Box>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={getValues('priority')}
                                        onChange={onPriorityChangeHandler}
                                    />}
                                    label={t('PRIORITY')}
                                    sx={{ marginLeft: 1, marginTop: 1 }}
                                />
                            </Box>

                            <Box flex={1}>
                                <TextareaField
                                    label={t('PRIORITY NOTES')}
                                    rows={1}
                                    value={getValues('priorityNotes')}
                                    onChange={onPriorityNotesChangeHandler}
                                />
                            </Box>
                        </Stack>

                        <Stack direction='row' spacing={2}>
                            <Box flex={1.13}>
                                <DateField
                                    label={t('DATE')}
                                    required={!isBlank(getValues('time'))}
                                    size='medium'
                                    value={getValues('date') || NaN}
                                    format={dateFormat}
                                    onChange={onDateChangeHandler}
                                />
                            </Box>

                            <Box flex={1}>
                                <TimeField
                                    label={t('TIME')}
                                    size='medium'
                                    hideSeconds
                                    value={getValues('time')}
                                    onChange={onTimeChangeHandler}
                                />
                            </Box>
                        </Stack>
                    </Stack>

                    <Stack direction='column' spacing={2} width={'100%'}>
                        <PalletTypeQuantity
                            data={entity ? entity.pallets : undefined}
                            onChange={onPalletsChangeHandler}
                        />

                        <StatusSelect
                            label={t('STATUS')}
                            required
                            data={statusData}
                            value={getValues('status')}
                            size='medium'
                            onChange={onChangeStatusHandler}
                        />
                    </Stack>
                </Stack>
            </form>
        );
    }, [
        handleSubmit, onSubmit, getValues, t, onChangeIdnoHandler,
        onDescriptionChangeHandler, entity, direction, onNotesChangeHandler,
        onLoadItemTypeChangeHandler, onFragilityLevelChangeHandler, onQuantityChangeHandler,
        mass, onWeightChangeHandler, temperature, onTemperatureChangeHandler,
        onAddressTypeChangeHandler, warehouseRefresh, onWarehouseInitHandler,
        onWarehouseChangeHandler, onWarehouseToggleHandler, companyAddressRefresh,
        onCompanyAddressInitHandler, onCompanyAddressChangeHandler, dateFormat,
        onCompanyAddressToggleHandler, address, onPriorityChangeHandler, onPriorityNotesChangeHandler,
        onDateChangeHandler, onTimeChangeHandler, onPalletsChangeHandler, onChangeStatusHandler
    ]);

    return (
        <>
            <BaseCrudDialog
                loading={loading}
                open={open}
                title={t(`${entity && !copy ? `EDIT ${entity.direction}` : `CREATE ${direction}`}`)}
                maxWidth={'lg'}
                formId={formId}
                buildContent={onBuildContent}
                saveBtnDisabled={!isValid || !isDirty}
                saveBtnLabel={t('SAVE')}
                onCloseBtnClick={onCloseBtnClick}
                closeBtnLabel={t('CLOSE')}
            />

            {warehouseToggle &&
                <WarehouseDialog
                    open={warehouseToggle}
                    statusActive
                    onSubmitBtnClick={onSubmitWarehouseHandler}
                    onCloseBtnClick={onWarehouseToggleHandler}
                />
            }

            {companyAddressToggle &&
                <CompanyAddressDialog
                    open={companyAddressToggle}
                    onSubmitBtnClick={onSubmitCompanyAddressHandler}
                    onCloseBtnClick={onCompanyAddressToggleHandler}
                />
            }
        </>
    );
}
export default LoadItemsDialog;