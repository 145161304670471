import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { normalizeNavigationHomeUrl } from "../../../helpers/generalHelper";
import { useLoad } from "../../../hooks/useLoad";
import { useNotification } from "../../../hooks/useNotification";
import { IStepItem } from "../../../models/CommonModels";
import { ILoadCoverFaceResponseDto } from "../../../models/LoadModels";
import LoadService from "../../../services/LoadService";
import { RootState } from "../../../store/store";
import BaseStep from "../../Base/BaseStepComponent/BaseStep";
import LoadChargesStep from "./Charges/LoadChargesStep";
import LoadDescriptionStep from "./Description/LoadDescriptionStep";
import LoadDocumentsStep from "./Documents/LoadDocumentsStep";
import LoadInvoiceStep from "./Invoice/LoadInvoiceStep";
import LoadIssuesStep from "./Issues/LoadIssuesStep";
import LoadItemsStep from "./Items/LoadItemsStep";
import LoadStepCoverFace from "./LoadStepCoverFace";
import LoadOverviewStep from "./Overview/LoadOverviewStep";
import LoadPaysStep from "./Pays/LoadPaysStep";
import LoadStatusStep from "./Status/LoadStatusStep";
import LoadTransportsStep from "./Transports/LoadTransportsStep";
import LoadUsersStep from "./Users/LoadUsersStep";

const LoadStep = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const navigate = useNavigate();
    const { refresh } = useSelector((state: RootState) => state.loadSlice.step);
    const { gridRefresh } = useLoad();

    const onRetrieveDataApiHandler = useCallback((id: string) => {
        return LoadService.fetchCoverFaceData(id);
    }, []);

    const onBuildCoverFaceHandler = useCallback((data: ILoadCoverFaceResponseDto): React.ReactNode => {
        return <LoadStepCoverFace data={data} />;
    }, []);

    const onBuildItemsHandler = useCallback((data: ILoadCoverFaceResponseDto): IStepItem[] => {
        const id: string = data.uuid;
        const readonly: boolean = data.readonly;
        return [{
            title: t('OVERVIEW'),
            content: <LoadOverviewStep loadId={id} />
        }, {
            title: t('SHIPPER & CONSIGNEE'),
            content: <LoadItemsStep loadId={id} />,
            required: true
        }, {
            title: t('USERS'),
            content: <LoadUsersStep loadId={id} />,
            required: true
        }, {
            title: t('TRANSPORTS'),
            content: <LoadTransportsStep loadId={id} />,
            required: true
        // }, {
        //     title: t('WAYPOINTS'),
        //     content: <></>,
        //     disabled: true
        }, {
            title: t('OTHER CHARGES'),
            content: <LoadChargesStep loadId={id} />
        }, {
            title: t('PAY'),
            content: <LoadPaysStep loadId={id} />
        // }, {
        //     title: t('INVOICE'),
        //     content: <LoadInvoiceStep loadId={id} status={data.status} />,
        //     required: true
        }, {
            title: t('ISSUES'),
            content: <LoadIssuesStep loadId={id} />
        }, {
            title: t('DOCUMENTS'),
            content: <LoadDocumentsStep loadId={id} />,
            required: true
        }, {
            title: t('DESCRIPTION'),
            content: <LoadDescriptionStep loadId={id} />
        }, {
            title: t('STATUS'),
            content: <LoadStatusStep loadId={id} />,
            required: true
        }];
    }, [t]);

    const onDeleteBtnClickHandler = useCallback((uuid: string) => {
        (async () => {
            const [error] = await LoadService.remove(uuid);

            if (error) {
                displayNotification({ type: 'error', message: error?.message });
            } else {
                const newUrl: string = normalizeNavigationHomeUrl(true);
                navigate(`/${newUrl}`);
                displayNotification({ message: t('Load was successfully deleted.') });
                gridRefresh();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    return (
        <BaseStep
            retrieveDataApi={onRetrieveDataApiHandler}
            refresh={refresh}
            buildCoverFace={onBuildCoverFaceHandler}
            buildItems={onBuildItemsHandler}
            onDeleteBtnClick={onDeleteBtnClickHandler}
            deleteBtnTooltip={t('Delete item')}
            closeBtnTooltip={t('Close and return to the main list')}
        />
    );
}
export default LoadStep;