import { Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import BaseDialog from "./BaseDialog";

interface IProps {
    open: boolean;
    title: string;
    message: string;
    onCloseBtnClick: () => void;
}
const BaseInfoDialog = (props: IProps) => {
    const { open, title, message, onCloseBtnClick } = props;
    const { t } = useTranslation();

    const onBuildContent = useCallback(() => {
        return (
            <Typography variant='body1'>
                {t(message)}
            </Typography>
        );
    }, [message, t]);

    return (
        <BaseDialog
            open={open}
            title={t(title)}
            maxWidth={'sm'}
            buildContent={onBuildContent}
            onCloseBtnClick={onCloseBtnClick}
            closeBtnLabel={t('CLOSE')}
            closeBtnIconHide
            closeBtnColor={'primary'}
            closeBtnVariant={'text'}
        />
    );
}
export default BaseInfoDialog;