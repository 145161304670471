import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../helpers/generalHelper";
import { useCustomer } from "../../../hooks/useCustomer";
import { useNotification } from '../../../hooks/useNotification';
import { IFilter } from '../../../models/CommonModels';
import { ICompanyAddressResponseDto } from "../../../models/CustomerModels";
import CustomerService from "../../../services/CustomerService";
import { RootState } from "../../../store/store";
import BaseCrudGrid from '../../Base/BaseCrudGridComponent/BaseCrudGrid';
import CompanyAddressDialog from "./CompanyAddressDialog";

const CompanyAaddressGrid = () => {
    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { companyAddressGridRefresh } = useCustomer();

    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.customerSlice.companyAddressGrid);

    const [dialogToggle, setDialogToggle] = useState(false);
    const [companyAddress, setCompanyAddress] = useState<ICompanyAddressResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return CustomerService.fetchCompanyAddresses(filter);
    }, []);

    const onAddActionBtnClick = useCallback(() => {
        setCompanyAddress(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((companyAddress: ICompanyAddressResponseDto) => {
        setCompanyAddress(companyAddress);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await CustomerService.removeCompanyAddress(rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`Address${rowIds.length > 1 ? 'es' : ''} was successfully deleted.`) });
                    companyAddressGridRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyAddressGridRefresh, t]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setCompanyAddress(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        companyAddressGridRefresh();
    }, [companyAddressGridRefresh]);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onAddressColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.row.address.value}>
                <span>{params.row.address.value}</span>
            </Tooltip>
        );
    }, []);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'name',
            headerName: t('NAME'),
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1.3,
            minWidth: 150,
            headerAlign: 'center',
            renderCell: onTooltipColumnRender
        }, {
            field: 'address',
            headerName: t('ADDRESS'),
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 2,
            minWidth: 200,
            headerAlign: 'center',
            renderCell: onAddressColumnRender
        }];
    }, [onAddressColumnRender, onTooltipColumnRender, t]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams, row: any) => {
        setCompanyAddress(row);
        setDialogToggle(true);
    }, []);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {dialogToggle &&
                <CompanyAddressDialog
                    open={dialogToggle}
                    companyAddress={companyAddress}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default CompanyAaddressGrid;