import DownloadIcon from '@mui/icons-material/Download';
import PageviewIcon from '@mui/icons-material/Pageview';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Box, IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDateAndTimeFormat } from '../../../helpers/dateHelper';
import { isEmpty } from '../../../helpers/generalHelper';
import { currencyFormat } from '../../../helpers/NumberHelper';
import { ECriteriaExpression, IBaseGridActionBtn, ICriteria, IFilter } from '../../../models/CommonModels';
import { ICustomerIdNameResponseDto } from '../../../models/CustomerModels';
import { IFileDetailsResponseDto } from '../../../models/FileModel';
import { IInvoiceResponseDto, IInvoiceSummaryResponseDto } from '../../../models/InvoiceModels';
import FileService from '../../../services/FileService';
import InvoiceService from '../../../services/InvoiceService';
import { RootState } from "../../../store/store";
import BaseCrudGrid from "../../Base/BaseCrudGridComponent/BaseCrudGrid";
import FileViewDialog from '../../Base/FileComponent/FileViewDialog';
import InvoiceDownloadDialog from './InvoiceDownloadDialog';
import InvoiceFilter from './InvoiceFilter';
import InvoiceStatusDialog from './InvoiceStatusDialog';

const InvoiceGrid = () => {
    const { t } = useTranslation();
    const { dateFormat, timeFormat } = useSelector((state: RootState) => state.preferenceSlice.user);
    const { mass } = useSelector((state: RootState) => state.preferenceSlice.global);
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.invoiceSlice.grid);

    const [filterToggle, setFilterToggle] = useState<boolean>(false);
    const [previewFileDialogToggle, setPreviewFileDialogToggle] = useState(false);
    const [previewFile, setPreviewFile] = useState<IFileDetailsResponseDto>();

    const [data, setData] = useState<IInvoiceResponseDto[]>([]);
    const [filter, setFilter] = useState<IFilter>();
    const [invoiceSummary, setInvoiceSummary] = useState<IInvoiceSummaryResponseDto>();

    const [downloadDialogToggle, setDownloadDialogToggle] = useState(false);
    const [statusDialogToggle, setStatusDialogToggle] = useState(false);

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        setFilter(filter);
        return InvoiceService.fetchAllByFilter(filter);
    }, []);

    const onInitData = useCallback((data: IInvoiceResponseDto[]) => {
        setData(data);
    }, []);

    const onIdnoColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: IInvoiceResponseDto = params.row;
        const value: string = params.value;
        return (
            <Tooltip title={value} sx={{ cursor: 'pointer' }}>
                <Link to={`/load/${row.loadId}`}>
                    {value}
                </Link>
            </Tooltip>
        );
    }, []);

    const onCustomerColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: ICustomerIdNameResponseDto = params.value;
        return (
            <Tooltip title={value.name.toUpperCase()} sx={{ cursor: 'pointer' }}>
                <Link to={`/customer/${value.uuid}`}>
                    {value.name.toUpperCase()}
                </Link>
            </Tooltip>
        );
    }, []);

    const onItemsColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: IInvoiceResponseDto = params.row;
        const shipper: number = row.totalShipperItems || 0;
        const consignee: number = row.totalConsigneeItems || 0;

        return (
            <Box sx={{ display: 'flex', gap: '25px', justifyContent: 'center' }}>
                <Tooltip title={`${t('SHIPPER: ')} ${shipper}`}>
                    <span style={{ color: 'rgb(27, 94, 32)' }}>{shipper}</span>
                </Tooltip>

                <Tooltip title={`${t('CONSIGNEE: ')} ${consignee}`}>
                    <span style={{ color: 'rgb(25, 118, 210)' }}>{consignee}</span>
                </Tooltip>
            </Box>
        );
    }, [t]);

    const onWeightColumnRender = useCallback((params: GridRenderCellParams) => {
        const row: IInvoiceResponseDto = params.row;
        const shipper: number = row.shipperWeight || 0;
        const consignee: number = row.consigneeWeight || 0;

        return (
            <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center' }}>
                <Tooltip title={`${t('SHIPPER: ')} ${shipper} ${mass}`}>
                    <span style={{ color: 'rgb(27, 94, 32)' }}>{shipper} {mass}</span>
                </Tooltip>

                <Tooltip title={`${t('CONSIGNEE: ')} ${consignee} ${mass}`}>
                    <span style={{ color: 'rgb(25, 118, 210)' }}>{consignee} {mass}</span>
                </Tooltip>
            </Box>
        );
    }, [mass, t]);

    const onPreviewFileDialogToggle = useCallback(() => {
        setPreviewFileDialogToggle(previewFileDialogToggle => !previewFileDialogToggle);
    }, []);

    const onPreviewFileActionBtnClick = useCallback((file: IFileDetailsResponseDto) => {
        setPreviewFile(file);
        onPreviewFileDialogToggle();
    }, [onPreviewFileDialogToggle]);

    const onDownloadFileActionBtnClick = useCallback((url: string) => {
        (async () => {
            const elements = url.split('/');
            const uuid = elements[elements.length - 1];
            FileService.downloadByUrl(`file/download/${uuid}`);
        })();
    }, []);

    const onFileColumnRender = useCallback((params: GridRenderCellParams,) => {
        const value: IFileDetailsResponseDto = params.value;
        if (!value) {
            return '';
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', justifyContent: 'center' }}>
                <IconButton size='small' color='info' onClick={() => onPreviewFileActionBtnClick(value)}>
                    <PageviewIcon />
                </IconButton>

                <IconButton size='small' color='info' onClick={() => onDownloadFileActionBtnClick(value.url)}>
                    <DownloadIcon />
                </IconButton>
            </Box>
        );
    }, [onDownloadFileActionBtnClick, onPreviewFileActionBtnClick]);

    const onStatusColumnRender = useCallback((value: string) => {
        return value.toString().replaceAll('_', ' ');
    }, []);

    const onAmountColumnRender = useCallback((params: GridRenderCellParams) => {
        const value: string = currencyFormat(params.value);
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onDateColumnRender = useCallback((params: GridRenderCellParams) => {
        const dateTime: string = getDateAndTimeFormat(params.value, dateFormat, timeFormat);
        return (
            <Tooltip title={dateTime}>
                <span>{dateTime}</span>
            </Tooltip>
        );
    }, [dateFormat, timeFormat]);

    const onCreatedColumnRender = useCallback((params: GridRenderCellParams) => {
        const author: string = params.value;
        return (
            <Tooltip title={author}>
                <span>{author}</span>
            </Tooltip>
        );
    }, []);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'idno',
            headerName: t('ID#'),
            width: 110,
            headerAlign: 'center',
            renderCell: onIdnoColumnRender
        }, {
            field: 'customer',
            headerName: t('CUSTOMER'),
            minWidth: 275,
            flex: 1,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onCustomerColumnRender
        }, {
            field: 'items',
            headerName: t('ITEMS'),
            width: 120,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onItemsColumnRender
        }, {
            field: 'weight',
            headerName: t('WEIGHT'),
            width: 200,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onWeightColumnRender
        }, {
            field: 'invoicePdf',
            headerName: t('INVOICE'),
            width: 180,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onFileColumnRender
        }, {
            field: 'completeInvoicePdf',
            headerName: t('COMPLETE INVOICE'),
            width: 180,
            headerAlign: 'center',
            sortable: false,
            disableColumnMenu: true,
            renderCell: onFileColumnRender
        }, {
            field: 'rate',
            headerName: t('RATE'),
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'charges',
            headerName: t('O. CHARGES'),
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'totalRate',
            headerName: t('TOTAL RATE'),
            width: 140,
            headerAlign: 'center',
            align: 'center',
            renderCell: onAmountColumnRender
        }, {
            field: 'status',
            headerName: t('LOAD STATUS'),
            width: 140,
            headerAlign: 'center',
            sortable: false,
            resizable: false,
            valueFormatter: onStatusColumnRender
        }, {
            field: 'createdDate',
            headerName: t('DATE'),
            width: 170,
            headerAlign: 'center',
            resizable: false,
            renderCell: onDateColumnRender
        }, {
            field: 'createdBy',
            headerName: t('CREATED'),
            width: 250,
            headerAlign: 'center',
            resizable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: onCreatedColumnRender
        }];
    }, [
        t, onIdnoColumnRender, onCustomerColumnRender, onItemsColumnRender,
        onWeightColumnRender, onFileColumnRender, onAmountColumnRender,
        onStatusColumnRender, onDateColumnRender, onCreatedColumnRender
    ]);

    const onFilterToggleHandler = useCallback(() => {
        setFilterToggle(!filterToggle);
    }, [filterToggle]);

    const onDownloadDialogToggle = useCallback(() => {
        setDownloadDialogToggle(downloadDialogToggle => !downloadDialogToggle);
    }, []);

    const fetchSummary = useCallback((filter: IFilter, callback: () => void) => {
        (async () => {
            const [, response] = await InvoiceService.fetchSummaryByFilter(filter);
            if (response) {
                const data: IInvoiceSummaryResponseDto = response.data.body;
                setInvoiceSummary(data);
                callback();
            }
        })();
        return Promise.resolve();
    }, []);

    const onDownloadInvoiceDialogHandler = useCallback((filter: IFilter) => {
        fetchSummary(filter, () => {
            setFilter(filter);
            onDownloadDialogToggle();
        })
        return Promise.resolve();
    }, [fetchSummary, onDownloadDialogToggle]);

    const onStatusDialogToggle = useCallback(() => {
        setStatusDialogToggle(statusDialogToggle => !statusDialogToggle);
    }, []);

    const onStatusToggleHandler = useCallback((row: any, selectedRowsIds?: string) => {
        if (filter) {
            let filterTmp: IFilter = { ...filter };

            if (selectedRowsIds) {
                const criteriasIds: ICriteria = {
                    property: "uuid",
                    value: selectedRowsIds,
                    expression: ECriteriaExpression.IN
                };

                const newCriteria: ICriteria[] = [criteriasIds];
                filterTmp.criteria = newCriteria;
            }

            fetchSummary(filterTmp, () => {
                onStatusDialogToggle();
            });

            setFilter(filterTmp);
        }
    }, [fetchSummary, filter, onStatusDialogToggle]);

    const actionBtns = useMemo((): IBaseGridActionBtn[] => {
        return [{
            tooltip: t('Bulk status edit'),
            onClick: onStatusToggleHandler,
            icon: <SwapHorizontalCircleIcon />,
            iconBtn: true,
            disabled: isEmpty(data),
            key: 'bulk-status-update-action-btn'
        }];
    }, [data, onStatusToggleHandler, t]);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                refreshActionBtnTooltip={t('Refresh data')}
                addActionBtnHide={true}
                editActionBtnHide={true}
                deleteActionBtnHide={true}
                filterActionBtnShow
                filterActionBtnTooltip={t('Search load(s)')}
                onFilterActionBtnClick={onFilterToggleHandler}
                downloadActionBtnShow
                downloadActionBtnTooltip={t('Download invoice(s)')}
                downloadActionBtnApi={onDownloadInvoiceDialogHandler}
                downloadActionBtnCustom
                actionBtns={actionBtns}
                onInitData={onInitData}
            />

            {filterToggle &&
                <InvoiceFilter
                    open={filterToggle}
                    onClose={onFilterToggleHandler}
                />
            }

            {previewFileDialogToggle && previewFile &&
                <FileViewDialog
                    fileId={''}
                    file={previewFile}
                    open={previewFileDialogToggle}
                    navigationBtnsHide
                    onCloseBtnClick={onPreviewFileDialogToggle}
                />
            }

            {downloadDialogToggle && filter && invoiceSummary &&
                <InvoiceDownloadDialog
                    filter={filter}
                    summary={invoiceSummary}
                    open={downloadDialogToggle}
                    onCloseBtnClick={onDownloadDialogToggle}
                />
            }

            {statusDialogToggle && filter && invoiceSummary &&
                <InvoiceStatusDialog
                    filter={filter}
                    summary={invoiceSummary}
                    open={statusDialogToggle}
                    onCloseBtnClick={onStatusDialogToggle}
                />
            }
        </>
    );
}
export default InvoiceGrid;