import { Box } from "@mui/material";

const styleWithFullWidth = {
    fontSize: '0.875rem',
    width: '100%'
};

const styleDefault = {
    fontSize: '0.875rem'
};

interface IProps {
    label: string;
    height: string | number;
    fullWidth?: boolean;
}
const NoData = (props: IProps) => {
    const { label, height, fullWidth = false } = props;

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            height={height}
            alignItems={'center'}
            sx={fullWidth ? styleWithFullWidth : styleDefault}
        >
            {label}
        </Box>
    );
}
export default NoData;