import { Tooltip } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../../../helpers/generalHelper";
import { currencyFormat, percentFormat } from "../../../../helpers/NumberHelper";
import { useNotification } from "../../../../hooks/useNotification";
import { useUser } from "../../../../hooks/useUser";
import { IFilter } from "../../../../models/CommonModels";
import { EUserIncomeType, IUserIncomeResponseDto } from "../../../../models/UserModels";
import UserService from "../../../../services/UserService";
import { RootState } from "../../../../store/store";
import BaseCrudGrid from "../../../Base/BaseCrudGridComponent/BaseCrudGrid";
import UserIncomeDialog from "./UserIncomeDialog";

interface IProps {
    userId: string;
}
const UserIncomeGrid = (props: IProps) => {
    const { userId } = props;

    const { t } = useTranslation();
    const { displayNotification } = useNotification();
    const { refresh, unselectRows, criterias } = useSelector((state: RootState) => state.userSlice.incomeGrid);
    const { incomeGridRefresh } = useUser();

    const [dialogToggle, setDialogToggle] = useState(false);
    const [income, setIncome] = useState<IUserIncomeResponseDto | undefined>();

    const onRetrieveDataApi = useCallback((filter: IFilter) => {
        return UserService.fetchIncomes(userId, filter);
    }, [userId]);

    const onAddActionBtnClick = useCallback(() => {
        setIncome(undefined);
        setDialogToggle(true);
    }, []);

    const onEditActionBtnClick = useCallback((income: IUserIncomeResponseDto) => {
        setIncome(income);
        setDialogToggle(true);
    }, []);

    const onDeleteActionBtnClick = useCallback((rowIds: string[]) => {
        if (!isEmpty(rowIds)) {
            (async () => {
                let errorMsg: string = '';
                for (const rowId of rowIds) {
                    const [error] = await UserService.removeIncome(userId, rowId);
                    if (error) {
                        errorMsg = error?.message;
                    }
                }

                if (errorMsg) {
                    displayNotification({ type: 'error', message: errorMsg });
                } else {
                    displayNotification({ message: t(`User income${rowIds.length > 1 ? 's' : ''} was successfully deleted.`) });
                    incomeGridRefresh();
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, userId]);

    const onTypeColumnRender = useCallback((params: GridRenderCellParams) => {
        const incomeType: EUserIncomeType = params.value as unknown as EUserIncomeType;
        const value: string = incomeType.toString().replaceAll('_', ' ');
        return (
            <Tooltip title={value}>
                <span>{value}</span>
            </Tooltip>
        );
    }, []);

    const onTooltipColumnRender = useCallback((params: GridRenderCellParams) => {
        return (
            <Tooltip title={params.value}>
                <span>{params.value}</span>
            </Tooltip>
        );
    }, []);

    const onAmountColumnRender = useCallback((value: number, row: IUserIncomeResponseDto) => {
        return row.type === EUserIncomeType.PERCENTAGE
            ? percentFormat(value)
            : currencyFormat(value)
    }, []);

    const columns = useMemo((): GridColDef[] => {
        return [{
            field: 'type',
            headerName: t('TYPE'),
            width: 230,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onTypeColumnRender
        }, {
            field: 'caption',
            headerName: t('NOTES'),
            flex: 1,
            minWidth: 250,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: onTooltipColumnRender
        }, {
            field: 'amount',
            headerName: t('AMOUNT'),
            width: 150,
            headerAlign: 'center',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            type: 'number',
            valueFormatter: onAmountColumnRender
        }];
    }, [onAmountColumnRender, onTooltipColumnRender, onTypeColumnRender, t]);

    const closeDialogHandler = useCallback(() => {
        setDialogToggle(false);
        setIncome(undefined);
    }, []);

    const onSubmitDialogHandler = useCallback(() => {
        incomeGridRefresh();
    }, [incomeGridRefresh]);

    const onRowDoubleClickHandler = useCallback((params: GridRowParams, row: any) => {
        setIncome(row);
        setDialogToggle(true);
    }, []);

    return (
        <>
            <BaseCrudGrid
                refresh={refresh}
                unselectRows={unselectRows}
                criterias={criterias}
                columns={columns}
                density={'compact'}
                checkboxSelection={true}
                retrieveDataApi={onRetrieveDataApi}
                onAddActionBtnClick={onAddActionBtnClick}
                addActionBtnIcon={false}
                addActionBtnTooltip={t('Add item')}
                addActionBtnLabel={t('ADD')}
                onEditActionBtnClick={onEditActionBtnClick}
                editActionBtnTooltip={t('Edit item')}
                editActionBtnLabel={t('EDIT')}
                onDeleteActionBtnClick={onDeleteActionBtnClick}
                deleteActionBtnTooltip={t('Delete item(s)')}
                deleteActionBtnLabel={t('DELETE')}
                refreshActionBtnTooltip={t('Refresh data')}
                onRowDoubleClick={onRowDoubleClickHandler}
            />

            {dialogToggle &&
                <UserIncomeDialog
                    open={dialogToggle}
                    userId={userId}
                    income={income}
                    onCloseBtnClick={closeDialogHandler}
                    onSubmitBtnClick={onSubmitDialogHandler}
                />
            }
        </>
    );
}
export default UserIncomeGrid;