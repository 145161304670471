import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoBox from '../../../Base/NotificationComponent/InfoBox';

const data: { title: string, message: string }[] = [{
    title: 'INCOMPLETE',
    message: 'it means that the load items, the driver(s) and the transport have not been set.'
}, {
    title: 'PENDING',
    message: 'it means that the load items, the driver(s) and the transport have been set. Load is ready to start.'
}, {
    title: 'IN PROGRESS',
    message: 'it means that the load is in the process of delivery.'
}, {
    title: 'DELIVERED',
    message: 'it means that the load is delivered and there is a Rate Confirmation and at least a Bill Of Lading.'
}, {
    title: 'INVOICED',
    message: 'it means that an invoice was issued for load.'
}, {
    title: 'UNPAID',
    message: 'it means that an invoice was issued and sent to payment but the payment has not yet been made.'
}, {
    title: 'PAID',
    message: 'it means that the customer has paid the invoice.'
}];

const LoadStatusLegend = () => {
    const { t } = useTranslation();

    return (
        <Stack spacing={2}>
            {data.map((item, index) =>
                <InfoBox
                    key={`legend-${index}`}
                    title={t(item.title)}
                    message={t(item.message)}
                />
            )}
        </Stack>
    );
}
export default LoadStatusLegend;