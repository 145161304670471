import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Box, Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { ELoadItemStatus, ILoadItemStatusRequestDto, ILoadItemStatusResponseDto } from '../../../../models/LoadModels';
import LoadService from '../../../../services/LoadService';
import { RootState } from '../../../../store/store';
import BaseDialog from "../../../Base/BaseDialogComponent/BaseDialog";

interface IProps {
    entityId: string;
    open: boolean;
    onCloseBtnClick: (items: ILoadItemStatusRequestDto[]) => void;
    onSubmitBtnClick: (items: ILoadItemStatusRequestDto[]) => void;
}
const LoadItemsStatusDialog = (props: IProps) => {
    const { entityId, open, onCloseBtnClick, onSubmitBtnClick } = props;

    const { t } = useTranslation();
    const { mass } = useSelector((state: RootState) => state.preferenceSlice.global);

    const [initData, setInitData] = useState<ILoadItemStatusResponseDto[]>([]);
    const [data, setData] = useState<ILoadItemStatusResponseDto[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        (async () => {
            const [, response] = await LoadService.fetchItemsStatuses(entityId);
            if (response) {
                const data: ILoadItemStatusResponseDto[] = response.data.body;
                setInitData(data);
                setData(data);
            }
            setLoading(false);
        })();
    }, [entityId]);

    const onItemClickHandler = useCallback((newItem: ILoadItemStatusResponseDto) => {
        const newStatus: ELoadItemStatus = newItem.status === ELoadItemStatus.DELIVERED
            ? ELoadItemStatus.OPEN
            : ELoadItemStatus.DELIVERED;

        const newData: ILoadItemStatusResponseDto[] = data.map(item =>
            (item.uuid === newItem.uuid ? { ...item, status: newStatus } : item));

        setData(newData);
    }, [data]);

    const buildItemLabel = useCallback((item: ILoadItemStatusResponseDto) => {
        return (
            <Box>
                <Typography variant='body2'>
                    {`#${item.idno} | ${item.direction} | ${item.type} | ${t('QTY')}: ${item.quantity} | ${t('WT')}: ${item.weight} ${mass}`}
                </Typography>
                <Typography variant='caption' sx={{ fontSize: 12 }}>
                    {item.address}
                </Typography>
            </Box>
        );
    }, [mass, t]);

    const onBuildContent = useCallback(() => {
        return (
            <Box>
                <Typography variant="button">
                    {t('Select the items that are delivered:')}
                </Typography>

                <List sx={{ marginTop: 3, maxHeight: 300, overflow: 'auto' }}>
                    {data.map((item, index) => {
                        const labelId = `checkbox-list-label-${item.uuid}`;

                        return (
                            <ListItem key={`load-item-status-${index}`} disablePadding>
                                <ListItemButton role={undefined} onClick={() => onItemClickHandler(item)} dense>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge='start'
                                            checked={ELoadItemStatus.DELIVERED === item.status}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={buildItemLabel(item)} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        );
    }, [buildItemLabel, data, onItemClickHandler, t]);

    const onCloseBtnClickHandler = useCallback(() => {
        const requestData: ILoadItemStatusRequestDto[] = initData.map(item => { return { ...item } });
        onCloseBtnClick(requestData);
    }, [initData, onCloseBtnClick]);

    const onSubmitBtnClickHandler = useCallback(() => {
        const requestData: ILoadItemStatusRequestDto[] = data.map(item => { return { ...item } });
        onSubmitBtnClick(requestData);
    }, [data, onSubmitBtnClick]);

    const onBuildRightActions = useCallback(() => {
        return (
            <Tooltip title={t('APPLY')} arrow>
                <span>
                    <Button
                        variant='contained'
                        size='small'
                        startIcon={<SaveAsIcon />}
                        onClick={onSubmitBtnClickHandler}
                    >
                        {t('APPLY')}
                    </Button>
                </span>
            </Tooltip>
        );
    }, [onSubmitBtnClickHandler, t]);

    return (
        <BaseDialog
            loading={loading}
            open={open}
            title={t('UPDATE ITEMS STATUS')}
            maxWidth={'sm'}
            buildContent={onBuildContent}
            buildRightActions={onBuildRightActions}
            onCloseBtnClick={onCloseBtnClickHandler}
            closeBtnLabel={t('CLOSE')}
        />
    );
}
export default LoadItemsStatusDialog;