import { useCallback, useEffect } from "react";
import { usePreference } from "../../../hooks/usePreference";
import { useUserProfile } from "../../../hooks/useUserProfile";
import { IGlobalPreference, ISystemPreference, IUserPreference } from "../../../models/PreferenceModels";
import { IUserProfileResponseDto } from "../../../models/UserProfileModels";
import GlobalPreferenceService from "../../../services/GlobalPreferenceService";
import SystemPreferenceService from "../../../services/SystemPreferenceService";
import UserPreferenceService from "../../../services/UserPreferenceService";
import UserProfileService from "../../../services/UserProfileService";

const AuthUserProfile = () => {
    const { updateGlobalPreference, updateUserPreference, updateSystemPreference } = usePreference();
    const { update } = useUserProfile();

    const fetchGlobalPreference = useCallback(() => {
        (async () => {
            const [, response] = await GlobalPreferenceService.fetch();
            if (response) {
                const preferences: IGlobalPreference = response.data.body;
                updateGlobalPreference({ ...preferences, loaded: true });
            }
        })();
    }, [updateGlobalPreference]);

    const fetchUserPreference = useCallback(() => {
        (async () => {
            const [, response] = await UserPreferenceService.fetch();
            if (response) {
                const preferences: IUserPreference = response.data.body;
                updateUserPreference({ ...preferences, loaded: true });
            }
        })();
    }, [updateUserPreference]);

    const fetchSystemPreference = useCallback(() => {
        (async () => {
            const [, response] = await SystemPreferenceService.fetch();
            if (response) {
                const preferences: ISystemPreference = response.data.body;
                updateSystemPreference({ ...preferences, loaded: true });
            }
        })();
    }, [updateSystemPreference]);

    const fetchUserProfile = useCallback(() => {
        (async () => {
            const [, response] = await UserProfileService.fetch();
            if (response) {
                const profile: IUserProfileResponseDto = response.data.body;
                update({ ...profile, loaded: true });
            }
        })();
    }, [update]);

    useEffect(() => {
        fetchGlobalPreference();
        fetchUserPreference();
        fetchSystemPreference();
        fetchUserProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<></>);
}
export default AuthUserProfile;